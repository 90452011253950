/* eslint-disable */
import * as Types from '../../../../graphql/gql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetAffiliatePropertiesQueryVariables = Types.Exact<{
  affiliateCode: Types.Scalars['String'];
}>;


export type GetAffiliatePropertiesQuery = { __typename?: 'Query', getAffiliateProperties?: Array<{ __typename?: 'AffiliateProperty', property_id?: string | undefined } | undefined> | undefined };

export type GetUserQueryVariables = Types.Exact<{
  id: Types.Scalars['Int'];
}>;


export type GetUserQuery = { __typename?: 'Query', getUser?: { __typename?: 'User', id?: string | undefined, email?: string | undefined, first_name?: string | undefined, initials?: string | undefined, last_name?: string | undefined, last_login?: string | undefined, login_id?: string | undefined, name?: string | undefined, okta_id?: string | undefined, prop_id?: Array<string | undefined> | undefined, role?: Types.UserRole | undefined, created_at?: string | undefined, updated_at?: string | undefined, super_admin?: boolean | undefined } | undefined };

export type GetUserByEmailQueryVariables = Types.Exact<{
  email: Types.Scalars['String'];
}>;


export type GetUserByEmailQuery = { __typename?: 'Query', getUserByEmail?: { __typename?: 'User', id?: string | undefined, email?: string | undefined, first_name?: string | undefined, initials?: string | undefined, last_name?: string | undefined, last_login?: string | undefined, login_id?: string | undefined, name?: string | undefined, okta_id?: string | undefined, prop_id?: Array<string | undefined> | undefined, role?: Types.UserRole | undefined, created_at?: string | undefined, updated_at?: string | undefined, super_admin?: boolean | undefined } | undefined };

export type GetUserByLoginIdQueryVariables = Types.Exact<{
  loginId: Types.Scalars['String'];
}>;


export type GetUserByLoginIdQuery = { __typename?: 'Query', getUserByLoginId?: { __typename?: 'User', id?: string | undefined, email?: string | undefined, first_name?: string | undefined, initials?: string | undefined, last_name?: string | undefined, last_login?: string | undefined, login_id?: string | undefined, name?: string | undefined, okta_id?: string | undefined, prop_id?: Array<string | undefined> | undefined, role?: Types.UserRole | undefined, created_at?: string | undefined, updated_at?: string | undefined, super_admin?: boolean | undefined } | undefined };

export type GetUserByOktaQueryVariables = Types.Exact<{
  okta_id: Types.Scalars['String'];
}>;


export type GetUserByOktaQuery = { __typename?: 'Query', getUserByOkta?: { __typename?: 'User', id?: string | undefined, email?: string | undefined, first_name?: string | undefined, initials?: string | undefined, last_name?: string | undefined, last_login?: string | undefined, login_id?: string | undefined, name?: string | undefined, okta_id?: string | undefined, prop_id?: Array<string | undefined> | undefined, role?: Types.UserRole | undefined, created_at?: string | undefined, updated_at?: string | undefined, super_admin?: boolean | undefined } | undefined };

export type CreateUserMutationVariables = Types.Exact<{
  email: Types.Scalars['String'];
  name: Types.Scalars['String'];
  first_name: Types.Scalars['String'];
  last_name: Types.Scalars['String'];
  initials?: Types.InputMaybe<Types.Scalars['String']>;
  login_id?: Types.InputMaybe<Types.Scalars['String']>;
  okta_id?: Types.InputMaybe<Types.Scalars['String']>;
  prop_id?: Types.InputMaybe<Array<Types.InputMaybe<Types.Scalars['String']>> | Types.InputMaybe<Types.Scalars['String']>>;
  role?: Types.InputMaybe<Types.UserRole>;
}>;


export type CreateUserMutation = { __typename?: 'Mutation', createUser?: { __typename?: 'User', id?: string | undefined, email?: string | undefined, first_name?: string | undefined, initials?: string | undefined, last_name?: string | undefined, login_id?: string | undefined, name?: string | undefined, okta_id?: string | undefined, prop_id?: Array<string | undefined> | undefined, role?: Types.UserRole | undefined, created_at?: string | undefined, updated_at?: string | undefined, super_admin?: boolean | undefined } | undefined };

export type UpdateUserMutationVariables = Types.Exact<{
  id: Types.Scalars['String'];
  email?: Types.InputMaybe<Types.Scalars['String']>;
  first_name?: Types.InputMaybe<Types.Scalars['String']>;
  last_name?: Types.InputMaybe<Types.Scalars['String']>;
  last_login?: Types.InputMaybe<Types.Scalars['String']>;
  name?: Types.InputMaybe<Types.Scalars['String']>;
  initials?: Types.InputMaybe<Types.Scalars['String']>;
  login_id?: Types.InputMaybe<Types.Scalars['String']>;
  okta_id?: Types.InputMaybe<Types.Scalars['String']>;
  prop_id?: Types.InputMaybe<Array<Types.InputMaybe<Types.Scalars['String']>> | Types.InputMaybe<Types.Scalars['String']>>;
  role?: Types.InputMaybe<Types.UserRole>;
}>;


export type UpdateUserMutation = { __typename?: 'Mutation', updateUser?: { __typename?: 'User', id?: string | undefined, email?: string | undefined, first_name?: string | undefined, initials?: string | undefined, last_name?: string | undefined, last_login?: string | undefined, login_id?: string | undefined, name?: string | undefined, okta_id?: string | undefined, prop_id?: Array<string | undefined> | undefined, role?: Types.UserRole | undefined, created_at?: string | undefined, updated_at?: string | undefined, super_admin?: boolean | undefined } | undefined };

export type CreateUserConnectionLogMutationVariables = Types.Exact<{
  email: Types.Scalars['String'];
  data: Types.Scalars['String'];
  success: Types.Scalars['Boolean'];
}>;


export type CreateUserConnectionLogMutation = { __typename?: 'Mutation', createUserConnectionLog?: { __typename?: 'UserConnectionLog', id?: string | undefined, email?: string | undefined, data?: string | undefined, success?: boolean | undefined, created_at?: string | undefined, updated_at?: string | undefined } | undefined };


export const GetAffiliatePropertiesDocument = gql`
    query GetAffiliateProperties($affiliateCode: String!) {
  getAffiliateProperties(affiliateCode: $affiliateCode) {
    property_id
  }
}
    `;

/**
 * __useGetAffiliatePropertiesQuery__
 *
 * To run a query within a React component, call `useGetAffiliatePropertiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAffiliatePropertiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAffiliatePropertiesQuery({
 *   variables: {
 *      affiliateCode: // value for 'affiliateCode'
 *   },
 * });
 */
export function useGetAffiliatePropertiesQuery(baseOptions: Apollo.QueryHookOptions<GetAffiliatePropertiesQuery, GetAffiliatePropertiesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAffiliatePropertiesQuery, GetAffiliatePropertiesQueryVariables>(GetAffiliatePropertiesDocument, options);
      }
export function useGetAffiliatePropertiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAffiliatePropertiesQuery, GetAffiliatePropertiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAffiliatePropertiesQuery, GetAffiliatePropertiesQueryVariables>(GetAffiliatePropertiesDocument, options);
        }
export type GetAffiliatePropertiesQueryHookResult = ReturnType<typeof useGetAffiliatePropertiesQuery>;
export type GetAffiliatePropertiesLazyQueryHookResult = ReturnType<typeof useGetAffiliatePropertiesLazyQuery>;
export type GetAffiliatePropertiesQueryResult = Apollo.QueryResult<GetAffiliatePropertiesQuery, GetAffiliatePropertiesQueryVariables>;
export const GetUserDocument = gql`
    query GetUser($id: Int!) {
  getUser(id: $id) {
    id
    email
    first_name
    initials
    last_name
    last_login
    login_id
    name
    okta_id
    prop_id
    role
    created_at
    updated_at
    super_admin
  }
}
    `;

/**
 * __useGetUserQuery__
 *
 * To run a query within a React component, call `useGetUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUserQuery(baseOptions: Apollo.QueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
      }
export function useGetUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
        }
export type GetUserQueryHookResult = ReturnType<typeof useGetUserQuery>;
export type GetUserLazyQueryHookResult = ReturnType<typeof useGetUserLazyQuery>;
export type GetUserQueryResult = Apollo.QueryResult<GetUserQuery, GetUserQueryVariables>;
export const GetUserByEmailDocument = gql`
    query GetUserByEmail($email: String!) {
  getUserByEmail(email: $email) {
    id
    email
    first_name
    initials
    last_name
    last_login
    login_id
    name
    okta_id
    prop_id
    role
    created_at
    updated_at
    super_admin
  }
}
    `;

/**
 * __useGetUserByEmailQuery__
 *
 * To run a query within a React component, call `useGetUserByEmailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserByEmailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserByEmailQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useGetUserByEmailQuery(baseOptions: Apollo.QueryHookOptions<GetUserByEmailQuery, GetUserByEmailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserByEmailQuery, GetUserByEmailQueryVariables>(GetUserByEmailDocument, options);
      }
export function useGetUserByEmailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserByEmailQuery, GetUserByEmailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserByEmailQuery, GetUserByEmailQueryVariables>(GetUserByEmailDocument, options);
        }
export type GetUserByEmailQueryHookResult = ReturnType<typeof useGetUserByEmailQuery>;
export type GetUserByEmailLazyQueryHookResult = ReturnType<typeof useGetUserByEmailLazyQuery>;
export type GetUserByEmailQueryResult = Apollo.QueryResult<GetUserByEmailQuery, GetUserByEmailQueryVariables>;
export const GetUserByLoginIdDocument = gql`
    query GetUserByLoginId($loginId: String!) {
  getUserByLoginId(loginId: $loginId) {
    id
    email
    first_name
    initials
    last_name
    last_login
    login_id
    name
    okta_id
    prop_id
    role
    created_at
    updated_at
    super_admin
  }
}
    `;

/**
 * __useGetUserByLoginIdQuery__
 *
 * To run a query within a React component, call `useGetUserByLoginIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserByLoginIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserByLoginIdQuery({
 *   variables: {
 *      loginId: // value for 'loginId'
 *   },
 * });
 */
export function useGetUserByLoginIdQuery(baseOptions: Apollo.QueryHookOptions<GetUserByLoginIdQuery, GetUserByLoginIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserByLoginIdQuery, GetUserByLoginIdQueryVariables>(GetUserByLoginIdDocument, options);
      }
export function useGetUserByLoginIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserByLoginIdQuery, GetUserByLoginIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserByLoginIdQuery, GetUserByLoginIdQueryVariables>(GetUserByLoginIdDocument, options);
        }
export type GetUserByLoginIdQueryHookResult = ReturnType<typeof useGetUserByLoginIdQuery>;
export type GetUserByLoginIdLazyQueryHookResult = ReturnType<typeof useGetUserByLoginIdLazyQuery>;
export type GetUserByLoginIdQueryResult = Apollo.QueryResult<GetUserByLoginIdQuery, GetUserByLoginIdQueryVariables>;
export const GetUserByOktaDocument = gql`
    query GetUserByOkta($okta_id: String!) {
  getUserByOkta(okta_id: $okta_id) {
    id
    email
    first_name
    initials
    last_name
    last_login
    login_id
    name
    okta_id
    prop_id
    role
    created_at
    updated_at
    super_admin
  }
}
    `;

/**
 * __useGetUserByOktaQuery__
 *
 * To run a query within a React component, call `useGetUserByOktaQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserByOktaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserByOktaQuery({
 *   variables: {
 *      okta_id: // value for 'okta_id'
 *   },
 * });
 */
export function useGetUserByOktaQuery(baseOptions: Apollo.QueryHookOptions<GetUserByOktaQuery, GetUserByOktaQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserByOktaQuery, GetUserByOktaQueryVariables>(GetUserByOktaDocument, options);
      }
export function useGetUserByOktaLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserByOktaQuery, GetUserByOktaQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserByOktaQuery, GetUserByOktaQueryVariables>(GetUserByOktaDocument, options);
        }
export type GetUserByOktaQueryHookResult = ReturnType<typeof useGetUserByOktaQuery>;
export type GetUserByOktaLazyQueryHookResult = ReturnType<typeof useGetUserByOktaLazyQuery>;
export type GetUserByOktaQueryResult = Apollo.QueryResult<GetUserByOktaQuery, GetUserByOktaQueryVariables>;
export const CreateUserDocument = gql`
    mutation CreateUser($email: String!, $name: String!, $first_name: String!, $last_name: String!, $initials: String, $login_id: String, $okta_id: String, $prop_id: [String], $role: UserRole) {
  createUser(
    email: $email
    first_name: $first_name
    last_name: $last_name
    name: $name
    initials: $initials
    login_id: $login_id
    okta_id: $okta_id
    prop_id: $prop_id
    role: $role
  ) {
    id
    email
    first_name
    initials
    last_name
    login_id
    name
    okta_id
    prop_id
    role
    created_at
    updated_at
    super_admin
  }
}
    `;
export type CreateUserMutationFn = Apollo.MutationFunction<CreateUserMutation, CreateUserMutationVariables>;

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      email: // value for 'email'
 *      name: // value for 'name'
 *      first_name: // value for 'first_name'
 *      last_name: // value for 'last_name'
 *      initials: // value for 'initials'
 *      login_id: // value for 'login_id'
 *      okta_id: // value for 'okta_id'
 *      prop_id: // value for 'prop_id'
 *      role: // value for 'role'
 *   },
 * });
 */
export function useCreateUserMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserMutation, CreateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUserMutation, CreateUserMutationVariables>(CreateUserDocument, options);
      }
export type CreateUserMutationHookResult = ReturnType<typeof useCreateUserMutation>;
export type CreateUserMutationResult = Apollo.MutationResult<CreateUserMutation>;
export type CreateUserMutationOptions = Apollo.BaseMutationOptions<CreateUserMutation, CreateUserMutationVariables>;
export const UpdateUserDocument = gql`
    mutation UpdateUser($id: String!, $email: String, $first_name: String, $last_name: String, $last_login: String, $name: String, $initials: String, $login_id: String, $okta_id: String, $prop_id: [String], $role: UserRole) {
  updateUser(
    id: $id
    email: $email
    first_name: $first_name
    last_name: $last_name
    last_login: $last_login
    name: $name
    initials: $initials
    login_id: $login_id
    okta_id: $okta_id
    prop_id: $prop_id
    role: $role
  ) {
    id
    email
    first_name
    initials
    last_name
    last_login
    login_id
    name
    okta_id
    prop_id
    role
    created_at
    updated_at
    super_admin
  }
}
    `;
export type UpdateUserMutationFn = Apollo.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *      email: // value for 'email'
 *      first_name: // value for 'first_name'
 *      last_name: // value for 'last_name'
 *      last_login: // value for 'last_login'
 *      name: // value for 'name'
 *      initials: // value for 'initials'
 *      login_id: // value for 'login_id'
 *      okta_id: // value for 'okta_id'
 *      prop_id: // value for 'prop_id'
 *      role: // value for 'role'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, options);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;
export const CreateUserConnectionLogDocument = gql`
    mutation CreateUserConnectionLog($email: String!, $data: String!, $success: Boolean!) {
  createUserConnectionLog(email: $email, data: $data, success: $success) {
    id
    email
    data
    success
    created_at
    updated_at
  }
}
    `;
export type CreateUserConnectionLogMutationFn = Apollo.MutationFunction<CreateUserConnectionLogMutation, CreateUserConnectionLogMutationVariables>;

/**
 * __useCreateUserConnectionLogMutation__
 *
 * To run a mutation, you first call `useCreateUserConnectionLogMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserConnectionLogMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserConnectionLogMutation, { data, loading, error }] = useCreateUserConnectionLogMutation({
 *   variables: {
 *      email: // value for 'email'
 *      data: // value for 'data'
 *      success: // value for 'success'
 *   },
 * });
 */
export function useCreateUserConnectionLogMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserConnectionLogMutation, CreateUserConnectionLogMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUserConnectionLogMutation, CreateUserConnectionLogMutationVariables>(CreateUserConnectionLogDocument, options);
      }
export type CreateUserConnectionLogMutationHookResult = ReturnType<typeof useCreateUserConnectionLogMutation>;
export type CreateUserConnectionLogMutationResult = Apollo.MutationResult<CreateUserConnectionLogMutation>;
export type CreateUserConnectionLogMutationOptions = Apollo.BaseMutationOptions<CreateUserConnectionLogMutation, CreateUserConnectionLogMutationVariables>;
export const namedOperations = {
  Query: {
    GetAffiliateProperties: 'GetAffiliateProperties',
    GetUser: 'GetUser',
    GetUserByEmail: 'GetUserByEmail',
    GetUserByLoginId: 'GetUserByLoginId',
    GetUserByOkta: 'GetUserByOkta'
  },
  Mutation: {
    CreateUser: 'CreateUser',
    UpdateUser: 'UpdateUser',
    CreateUserConnectionLog: 'CreateUserConnectionLog'
  }
}