/* eslint-disable */
import * as Types from '../../../../graphql/gql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetNetBenefitQueryVariables = Types.Exact<{
  startDate: Types.Scalars['String'];
  endDate: Types.Scalars['String'];
  propertyId: Types.Scalars['String'];
}>;


export type GetNetBenefitQuery = { __typename?: 'Query', getNetBenefit: Array<{ __typename?: 'NetBenefitResult', current_otb?: number | undefined, current_rate?: number | undefined, group_booked?: number | undefined, stay_date?: string | undefined, total_capacity?: number | undefined, total_fcst?: number | undefined, fcst_rev?: number | undefined } | undefined> };

export type GroupPricingFragmentFragment = { __typename?: 'GroupPricing', id?: string | undefined, property_id?: string | undefined, group_name?: string | undefined, check_in?: string | undefined, check_out?: string | undefined, rooms_requested?: number | undefined, rate_requested?: number | undefined, commission?: number | undefined, direct_var_opex?: number | undefined, wash_factor?: number | undefined, rebate?: number | undefined, profit_margin?: number | undefined, f_and_b_profit?: number | undefined, meeting_space_benefit?: number | undefined, comp_rooms?: number | undefined, occ_fcst_override?: number | undefined, adr_fcst_override?: number | undefined, planners_adv_points?: number | undefined, avg_stay_length?: number | undefined, accepted?: boolean | undefined, user_id?: string | undefined, user_email?: string | undefined, created_at?: string | undefined, updated_at?: string | undefined };

export type GetRoomsRemainingQueryVariables = Types.Exact<{
  startDate: Types.Scalars['String'];
  endDate: Types.Scalars['String'];
  propertyId: Types.Scalars['String'];
}>;


export type GetRoomsRemainingQuery = { __typename?: 'Query', getRoomsRemaining: Array<{ __typename?: 'RoomType', resort_id?: string | undefined, stay_date?: string | undefined, rt_bed_type?: string | undefined, rt_description?: string | undefined, rt_remaining?: number | undefined, snapshot_date?: string | undefined, property_id?: string | undefined } | undefined> };

export type GetSavedGroupsQueryVariables = Types.Exact<{
  propertyId: Types.Scalars['String'];
  userId: Types.Scalars['String'];
}>;


export type GetSavedGroupsQuery = { __typename?: 'Query', getSavedGroups?: Array<{ __typename?: 'GroupPricing', id?: string | undefined, property_id?: string | undefined, group_name?: string | undefined, check_in?: string | undefined, check_out?: string | undefined, rooms_requested?: number | undefined, rate_requested?: number | undefined, commission?: number | undefined, direct_var_opex?: number | undefined, wash_factor?: number | undefined, rebate?: number | undefined, profit_margin?: number | undefined, f_and_b_profit?: number | undefined, meeting_space_benefit?: number | undefined, comp_rooms?: number | undefined, occ_fcst_override?: number | undefined, adr_fcst_override?: number | undefined, planners_adv_points?: number | undefined, avg_stay_length?: number | undefined, accepted?: boolean | undefined, user_id?: string | undefined, user_email?: string | undefined, created_at?: string | undefined, updated_at?: string | undefined } | undefined> | undefined };

export type SaveGroupPricingMutationVariables = Types.Exact<{
  group: Types.GroupPricingInput;
}>;


export type SaveGroupPricingMutation = { __typename?: 'Mutation', saveGroupPricing: { __typename?: 'GroupPricing', id?: string | undefined, property_id?: string | undefined, group_name?: string | undefined, check_in?: string | undefined, check_out?: string | undefined, rooms_requested?: number | undefined, rate_requested?: number | undefined, commission?: number | undefined, direct_var_opex?: number | undefined, wash_factor?: number | undefined, rebate?: number | undefined, profit_margin?: number | undefined, f_and_b_profit?: number | undefined, meeting_space_benefit?: number | undefined, comp_rooms?: number | undefined, occ_fcst_override?: number | undefined, adr_fcst_override?: number | undefined, planners_adv_points?: number | undefined, avg_stay_length?: number | undefined, accepted?: boolean | undefined, user_id?: string | undefined, user_email?: string | undefined, created_at?: string | undefined, updated_at?: string | undefined } };

export type UpdateGroupPricingMutationVariables = Types.Exact<{
  group: Types.GroupPricingInput;
}>;


export type UpdateGroupPricingMutation = { __typename?: 'Mutation', updateGroupPricing: { __typename?: 'GroupPricing', id?: string | undefined, property_id?: string | undefined, group_name?: string | undefined, check_in?: string | undefined, check_out?: string | undefined, rooms_requested?: number | undefined, rate_requested?: number | undefined, commission?: number | undefined, direct_var_opex?: number | undefined, wash_factor?: number | undefined, rebate?: number | undefined, profit_margin?: number | undefined, f_and_b_profit?: number | undefined, meeting_space_benefit?: number | undefined, comp_rooms?: number | undefined, occ_fcst_override?: number | undefined, adr_fcst_override?: number | undefined, planners_adv_points?: number | undefined, avg_stay_length?: number | undefined, accepted?: boolean | undefined, user_id?: string | undefined, user_email?: string | undefined, created_at?: string | undefined, updated_at?: string | undefined } };

export type DeleteGroupPricingMutationVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;


export type DeleteGroupPricingMutation = { __typename?: 'Mutation', deleteGroupPricing?: string | undefined };

export const GroupPricingFragmentFragmentDoc = gql`
    fragment GroupPricingFragment on GroupPricing {
  id
  property_id
  group_name
  check_in
  check_out
  rooms_requested
  rate_requested
  commission
  direct_var_opex
  wash_factor
  rebate
  profit_margin
  f_and_b_profit
  meeting_space_benefit
  comp_rooms
  occ_fcst_override
  adr_fcst_override
  planners_adv_points
  avg_stay_length
  accepted
  user_id
  user_email
  created_at
  updated_at
}
    `;
export const GetNetBenefitDocument = gql`
    query GetNetBenefit($startDate: String!, $endDate: String!, $propertyId: String!) {
  getNetBenefit(startDate: $startDate, endDate: $endDate, propertyId: $propertyId) {
    current_otb
    current_rate
    group_booked
    stay_date
    total_capacity
    total_fcst
    fcst_rev
  }
}
    `;

/**
 * __useGetNetBenefitQuery__
 *
 * To run a query within a React component, call `useGetNetBenefitQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNetBenefitQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNetBenefitQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      propertyId: // value for 'propertyId'
 *   },
 * });
 */
export function useGetNetBenefitQuery(baseOptions: Apollo.QueryHookOptions<GetNetBenefitQuery, GetNetBenefitQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetNetBenefitQuery, GetNetBenefitQueryVariables>(GetNetBenefitDocument, options);
      }
export function useGetNetBenefitLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetNetBenefitQuery, GetNetBenefitQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetNetBenefitQuery, GetNetBenefitQueryVariables>(GetNetBenefitDocument, options);
        }
export type GetNetBenefitQueryHookResult = ReturnType<typeof useGetNetBenefitQuery>;
export type GetNetBenefitLazyQueryHookResult = ReturnType<typeof useGetNetBenefitLazyQuery>;
export type GetNetBenefitQueryResult = Apollo.QueryResult<GetNetBenefitQuery, GetNetBenefitQueryVariables>;
export const GetRoomsRemainingDocument = gql`
    query GetRoomsRemaining($startDate: String!, $endDate: String!, $propertyId: String!) {
  getRoomsRemaining(
    startDate: $startDate
    endDate: $endDate
    propertyId: $propertyId
  ) {
    resort_id
    stay_date
    rt_bed_type
    rt_description
    rt_remaining
    snapshot_date
    property_id
  }
}
    `;

/**
 * __useGetRoomsRemainingQuery__
 *
 * To run a query within a React component, call `useGetRoomsRemainingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRoomsRemainingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRoomsRemainingQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      propertyId: // value for 'propertyId'
 *   },
 * });
 */
export function useGetRoomsRemainingQuery(baseOptions: Apollo.QueryHookOptions<GetRoomsRemainingQuery, GetRoomsRemainingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRoomsRemainingQuery, GetRoomsRemainingQueryVariables>(GetRoomsRemainingDocument, options);
      }
export function useGetRoomsRemainingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRoomsRemainingQuery, GetRoomsRemainingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRoomsRemainingQuery, GetRoomsRemainingQueryVariables>(GetRoomsRemainingDocument, options);
        }
export type GetRoomsRemainingQueryHookResult = ReturnType<typeof useGetRoomsRemainingQuery>;
export type GetRoomsRemainingLazyQueryHookResult = ReturnType<typeof useGetRoomsRemainingLazyQuery>;
export type GetRoomsRemainingQueryResult = Apollo.QueryResult<GetRoomsRemainingQuery, GetRoomsRemainingQueryVariables>;
export const GetSavedGroupsDocument = gql`
    query GetSavedGroups($propertyId: String!, $userId: String!) {
  getSavedGroups(propertyId: $propertyId, userId: $userId) {
    ...GroupPricingFragment
  }
}
    ${GroupPricingFragmentFragmentDoc}`;

/**
 * __useGetSavedGroupsQuery__
 *
 * To run a query within a React component, call `useGetSavedGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSavedGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSavedGroupsQuery({
 *   variables: {
 *      propertyId: // value for 'propertyId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetSavedGroupsQuery(baseOptions: Apollo.QueryHookOptions<GetSavedGroupsQuery, GetSavedGroupsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSavedGroupsQuery, GetSavedGroupsQueryVariables>(GetSavedGroupsDocument, options);
      }
export function useGetSavedGroupsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSavedGroupsQuery, GetSavedGroupsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSavedGroupsQuery, GetSavedGroupsQueryVariables>(GetSavedGroupsDocument, options);
        }
export type GetSavedGroupsQueryHookResult = ReturnType<typeof useGetSavedGroupsQuery>;
export type GetSavedGroupsLazyQueryHookResult = ReturnType<typeof useGetSavedGroupsLazyQuery>;
export type GetSavedGroupsQueryResult = Apollo.QueryResult<GetSavedGroupsQuery, GetSavedGroupsQueryVariables>;
export const SaveGroupPricingDocument = gql`
    mutation SaveGroupPricing($group: GroupPricingInput!) {
  saveGroupPricing(group: $group) {
    ...GroupPricingFragment
  }
}
    ${GroupPricingFragmentFragmentDoc}`;
export type SaveGroupPricingMutationFn = Apollo.MutationFunction<SaveGroupPricingMutation, SaveGroupPricingMutationVariables>;

/**
 * __useSaveGroupPricingMutation__
 *
 * To run a mutation, you first call `useSaveGroupPricingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveGroupPricingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveGroupPricingMutation, { data, loading, error }] = useSaveGroupPricingMutation({
 *   variables: {
 *      group: // value for 'group'
 *   },
 * });
 */
export function useSaveGroupPricingMutation(baseOptions?: Apollo.MutationHookOptions<SaveGroupPricingMutation, SaveGroupPricingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveGroupPricingMutation, SaveGroupPricingMutationVariables>(SaveGroupPricingDocument, options);
      }
export type SaveGroupPricingMutationHookResult = ReturnType<typeof useSaveGroupPricingMutation>;
export type SaveGroupPricingMutationResult = Apollo.MutationResult<SaveGroupPricingMutation>;
export type SaveGroupPricingMutationOptions = Apollo.BaseMutationOptions<SaveGroupPricingMutation, SaveGroupPricingMutationVariables>;
export const UpdateGroupPricingDocument = gql`
    mutation UpdateGroupPricing($group: GroupPricingInput!) {
  updateGroupPricing(group: $group) {
    ...GroupPricingFragment
  }
}
    ${GroupPricingFragmentFragmentDoc}`;
export type UpdateGroupPricingMutationFn = Apollo.MutationFunction<UpdateGroupPricingMutation, UpdateGroupPricingMutationVariables>;

/**
 * __useUpdateGroupPricingMutation__
 *
 * To run a mutation, you first call `useUpdateGroupPricingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateGroupPricingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateGroupPricingMutation, { data, loading, error }] = useUpdateGroupPricingMutation({
 *   variables: {
 *      group: // value for 'group'
 *   },
 * });
 */
export function useUpdateGroupPricingMutation(baseOptions?: Apollo.MutationHookOptions<UpdateGroupPricingMutation, UpdateGroupPricingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateGroupPricingMutation, UpdateGroupPricingMutationVariables>(UpdateGroupPricingDocument, options);
      }
export type UpdateGroupPricingMutationHookResult = ReturnType<typeof useUpdateGroupPricingMutation>;
export type UpdateGroupPricingMutationResult = Apollo.MutationResult<UpdateGroupPricingMutation>;
export type UpdateGroupPricingMutationOptions = Apollo.BaseMutationOptions<UpdateGroupPricingMutation, UpdateGroupPricingMutationVariables>;
export const DeleteGroupPricingDocument = gql`
    mutation DeleteGroupPricing($id: String!) {
  deleteGroupPricing(id: $id)
}
    `;
export type DeleteGroupPricingMutationFn = Apollo.MutationFunction<DeleteGroupPricingMutation, DeleteGroupPricingMutationVariables>;

/**
 * __useDeleteGroupPricingMutation__
 *
 * To run a mutation, you first call `useDeleteGroupPricingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteGroupPricingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteGroupPricingMutation, { data, loading, error }] = useDeleteGroupPricingMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteGroupPricingMutation(baseOptions?: Apollo.MutationHookOptions<DeleteGroupPricingMutation, DeleteGroupPricingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteGroupPricingMutation, DeleteGroupPricingMutationVariables>(DeleteGroupPricingDocument, options);
      }
export type DeleteGroupPricingMutationHookResult = ReturnType<typeof useDeleteGroupPricingMutation>;
export type DeleteGroupPricingMutationResult = Apollo.MutationResult<DeleteGroupPricingMutation>;
export type DeleteGroupPricingMutationOptions = Apollo.BaseMutationOptions<DeleteGroupPricingMutation, DeleteGroupPricingMutationVariables>;
export const namedOperations = {
  Query: {
    GetNetBenefit: 'GetNetBenefit',
    GetRoomsRemaining: 'GetRoomsRemaining',
    GetSavedGroups: 'GetSavedGroups'
  },
  Mutation: {
    SaveGroupPricing: 'SaveGroupPricing',
    UpdateGroupPricing: 'UpdateGroupPricing',
    DeleteGroupPricing: 'DeleteGroupPricing'
  },
  Fragment: {
    GroupPricingFragment: 'GroupPricingFragment'
  }
}