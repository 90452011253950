import './Header.scss';

import { DataProcessingMessage } from 'components/data-processing-message';
import PropertyDetails from './propertyDetails';
import { SupportMessage } from 'components/support-message';
import UserDetails from './userDetails';
import { useLocation } from 'react-router-dom';
import { useUser } from 'features/users/context/userContext';

export const Header = () => {
  // If on Home page and logged in, the header needs to be reduced in width
  const location = useLocation();
  const onHomePage = location.pathname.startsWith('/home');
  const { isAuthenticated } = useUser();
  const altWidth = onHomePage && isAuthenticated;

  return (
    <>
      <div className={`header ${altWidth ? 'alt-width' : ''}`}>
        {altWidth && <div className='filler-el' />}
        <div className='header__content'>
          <div
            className={`header__left ${isAuthenticated ? 'user' : 'no-user'}`}
          >
            <PropertyDetails />
          </div>
          <div className='header__right'>
            <UserDetails />
          </div>
        </div>
      </div>
      {isAuthenticated && <SupportMessage />}
      {isAuthenticated && <DataProcessingMessage />}
    </>
  );
};
