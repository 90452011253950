import './RecReviewCharts.scss';

import { ChartData, RecReviewChartDataType } from 'types/RecReviewTypes';
import { buildInventoryChartOptions, inventoryChartSeries } from './config';

import { Chart } from 'components/Chart/Chart';
import { LoadingBar } from 'components/loading-bar/loading-bar';
import { getCategories } from 'hooks/useRecReviewCharts';
import { labels } from 'locales/en.label';
import styles from 'common/_variables.module.scss';
import { usePropertyContext } from 'context/propertyContext';

export const InventoryChart = ({
  dayType,
  chartData,
  loading,
  days,
}: RecReviewChartDataType) => {
  const barChartKeys = [
    'overbooking',
    'out_of_order',
    'available_rooms',
    'transient_booked',
    'group_blocked',
    'group_booked',
  ];
  const { currencySymbol } = usePropertyContext();
  const inventoryChartOptions = buildInventoryChartOptions(currencySymbol);
  const xAxisCategory = getCategories(chartData?.stay_dates);
  const getIndividualBarDataObject = (
    name: string,
    data: any,
    type: string,
    color: string
  ) => {
    return {
      name: name,
      data: data,
      type: type,
      color: color,
    };
  };
  const getSeriesData = () => {
    let defaultBarColumns: any = {
      'Total Capacity': {
        name: labels.rec_review.dashboard.total_capacity,
        type: 'spline',
        data: chartData?.total_capacity,
        marker: {
          enabled: false,
        },
        dashStyle: 'shortdash',
        color: styles.graphDarkerGrey,
      },
      'BWH Rate': {
        name: labels.rec_review.dashboard.bw_rate,
        type: 'spline',
        zIndex: 1,
        yAxis: 1,
        tooltip: {
          valuePrefix: '$',
        },
        data: chartData?.bw_rates,
        color: styles.graphDarkBlue,
      },
    };
    for (var i = 0; i < barChartKeys.length; i++) {
      const key = barChartKeys[i] as keyof ChartData;
      if (chartData && chartData[key].length > 0) {
        defaultBarColumns[key] = getIndividualBarDataObject(
          inventoryChartSeries[key].name,
          chartData[key],
          inventoryChartSeries[key].type,
          inventoryChartSeries[key].color
        );
      }
    }
    return Object.values(defaultBarColumns);
  };
  let chartOptions = {
    xAxis: [
      {
        categories: xAxisCategory && xAxisCategory,
        lineWidth: 0,
        tickWidth: 0,
        crosshair: true,
        labels: {
          rotation: 0,
          formatter: function (this: any) {
            const text = this.value;
            if (typeof text === 'string' && days < 365) {
              const day = `${text.substring(0, 3)}<br/>${text.substring(8, 9)}`;
              if (
                (text.indexOf('Fri') > -1 || text.indexOf('Sat') > -1) &&
                dayType === 'all'
              ) {
                return '<span class="weekend">' + day + '</span>';
              }
              return '<span class="weekday">' + day + '</span>';
            }
            return text;
          },
        },
      },
      {
        title: {
          text: labels.rec_review.chart.stay_date,
          style: {
            color: styles.graphGrey,
          },
        },
      },
    ],
    series: getSeriesData(),
  };

  chartOptions = { ...inventoryChartOptions, ...chartOptions };

  return (
    <LoadingBar loading={loading}>
      <Chart options={chartOptions} />
    </LoadingBar>
  );
};
