import { useAppDispatch, useAppSelector } from 'redux/hooks';

import { setSupportMessage } from 'features/header/redux/header-slice';
import { useGetSupportMessageQuery } from 'features/control-panel/gql/_gen_/control-panel.gql';
import { useLocation } from 'react-router-dom';
import { useUser } from 'features/users/context/userContext';

export const SupportMessage = () => {
  const supportMessage = useAppSelector((state) => state.header.supportMessage);
  const dispatch = useAppDispatch();
  useGetSupportMessageQuery({
    onCompleted: (data) => {
      if (data?.getSupportMessage?.support_message) {
        dispatch(setSupportMessage(data.getSupportMessage?.support_message));
      }
    },
  });

  // If on Home page and logged in, the header needs to be reduced in width
  const location = useLocation();
  const onHomePage = location.pathname.includes('home');
  const { isAuthenticated } = useUser();
  const altWidth = onHomePage && isAuthenticated;

  const supportMessageStyle: React.CSSProperties = {
    backgroundColor: '#ffcccc', // Light red background color
    color: '#cc0000', // Darker red text color
    padding: '10px',
    marginTop: '10px',
    borderRadius: '5px',
    border: '1px solid #ff9999', // Light red border color
  };

  if (supportMessage && supportMessage !== '') {
    return (
      <div
        className={`header ${altWidth ? 'alt-width' : ''}`}
        style={{ zIndex: 10 }}
      >
        {altWidth && <div className='filler-el' />}
        <div className='header__content'>
          <div style={supportMessageStyle} role='alert'>
            {supportMessage}
          </div>
        </div>
      </div>
    );
  } else {
    return null;
  }
};
