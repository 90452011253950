import { Button, IconButton, Tooltip, capitalize } from '@mui/material';
import { FaCircle, FaRegCalendarCheck } from 'react-icons/fa';
import { dateFormatHomeDisplay, formatDate } from 'features/dates/date-helpers';
import { priorityColors, toDollarAmountDecimals } from 'pages/home/helpers';
import { useEffect, useState } from 'react';

import { CustomCard } from '../custom-card';
import { GroupIcon } from '../group-icon';
import { IoStar } from 'react-icons/io5';
import { LiveDataField } from 'components/live-data-field';
import { MetricsWithLiveData } from 'pages/home/hooks/use-prop-metrics';
import { RateOverrideField } from '../rate-override-field';
import { RecReviewStatus } from 'types/RecReviewTypes';
import { View } from '../inventory-rate-level-widget/inventory-rate-level-widget';
import _ from 'lodash';
import { labels } from 'locales/en.label';
import styles from 'common/_variables.module.scss';
import { useApolloError } from 'hooks/useApolloError';
import { useFavStayDate } from 'hooks/useFavStayDate';
import { usePropertyContext } from 'context/propertyContext';
import { useUser } from 'features/users/context/userContext';

type DayCardProps = {
  selected: boolean;
  metrics: MetricsWithLiveData;
  recStatusFilter: string | null;
  setDate: (date: string) => void;
  setView: (view: View) => void;
  onFilterChange: (field: string, value: string | number) => void;
  handleCardClick?: () => void;
  isCardSelected?: boolean;
};

export const DayCard = ({
  selected,
  metrics,
  setDate,
  setView,
  recStatusFilter,
  onFilterChange,
  handleCardClick,
  isCardSelected,
}: DayCardProps) => {
  const { isReadOnly } = useUser();
  const { stay_date, priority } = metrics;
  const [isFavorite, setIsFavorite] = useState(metrics.is_favorite);
  const [isDirty, setIsDirty] = useState(false); // Add this line
  useEffect(() => {
    setIsFavorite(metrics.is_favorite);
  }, [metrics.is_favorite]);

  const [favStayDate] = useFavStayDate(stay_date);
  const { handleApolloError } = useApolloError();
  const { currencySymbol } = usePropertyContext();

  const handleClick = () => {
    if (!handleCardClick) return;

    if (isDirty) return; // Prevent click if the input is dirty
    handleCardClick();
  };

  return (
    <CustomCard
      className='day-card'
      handleCardClick={handleClick}
      isSelected={isCardSelected}
    >
      <div className='content'>
        <div className='header'>
          <div className='left-section'>
            {!_.isNil(priority) && (
              <Tooltip
                title={`${capitalize(labels.common.rec_levels[priority])} ${
                  labels.home.tooltip.priority_filter
                }`}
                arrow
                placement='top'
              >
                <IconButton
                  aria-label={`${priority} priority`}
                  className='icon-btn priority-btn'
                  onClick={() => onFilterChange('priority', priority)}
                  disabled={isReadOnly}
                >
                  <FaCircle color={priorityColors[priority]} />
                </IconButton>
              </Tooltip>
            )}
            <Button
              variant='text'
              className='date-btn'
              disabled={selected || isReadOnly}
              onClick={() => setDate(stay_date)}
            >
              {formatDate(stay_date, dateFormatHomeDisplay)}
            </Button>
            <IconButton
              aria-label='favorite'
              className='icon-btn'
              disabled={isReadOnly}
              onClick={(e) => {
                e.stopPropagation();
                favStayDate({
                  refetchQueries: ['QueryUserFavNights'],
                  awaitRefetchQueries: true,
                  onError: (error) => {
                    handleApolloError(error);
                    setIsFavorite((prev) => !prev);
                  },
                });
                setIsFavorite((prev) => !prev);
              }}
            >
              <IoStar
                color={isFavorite ? styles.colorOrange : styles.colorNeutral}
              />
            </IconButton>
            {metrics.event_id && (
              <IconButton
                aria-label='event'
                className='icon-btn'
                onClick={() => console.log('TODO: Add functionality')}
              >
                <FaRegCalendarCheck color={styles.colorOrange} size={16} />
              </IconButton>
            )}
            <GroupIcon
              groupBooked={metrics.group_booked_live ?? metrics.group_booked}
              groupBlocked={metrics.group_blocked_live ?? metrics.group_blocked}
              setView={setView}
            />
          </div>
          <Tooltip
            title={
              recStatusFilter
                ? labels.home.tooltip.remove_filter
                : labels.home.tooltip.filter_rec_status
            }
            arrow
            placement='top'
          >
            <Button
              variant='outlined'
              className='btn rec-status-btn'
              disabled={isReadOnly}
              onClick={() =>
                onFilterChange('rec_status', RecReviewStatus.REVIEW)
              }
              style={
                recStatusFilter
                  ? {
                      fontWeight: 700,
                      border: `2px solid ${styles.colorBlue}`,
                    }
                  : undefined
              }
            >
              {labels.home.rec_statuses[metrics.rec_status]}
            </Button>
          </Tooltip>
        </div>

        <div className='row'>
          <div className='field-left'>
            <p className='label'>
              {labels.home.bw_rate} / {labels.home.yoy_rate}
            </p>
            <span>
              <LiveDataField
                liveVal={toDollarAmountDecimals(metrics.current_rate_live)}
                oldVal={toDollarAmountDecimals(metrics.current_rate)}
                enableCurrencyFormatter={true}
              />{' '}
              / {toDollarAmountDecimals(metrics.yoy_rate_lyst)}
            </span>
          </div>
          <div className='field-right'>
            <p className='label'>
              {labels.home.available_rooms} / {labels.home.otb}
            </p>
            <span>
              <LiveDataField
                liveVal={metrics.available_rooms_live}
                oldVal={metrics.available_rooms}
                enableCurrencyFormatter={false}
              />{' '}
              /{' '}
              <LiveDataField
                liveVal={metrics.current_otb_live}
                oldVal={metrics.current_otb}
                enableCurrencyFormatter={false}
              />
            </span>
          </div>
        </div>
        <div className='row'>
          <div className='field-left'>
            <p className='label'>
              {labels.home.rec_rate} / {labels.home.market_rate}
            </p>
            <span>
              {currencySymbol}
              {metrics.rec_rate?.toFixed(2)} / {currencySymbol}
              {metrics.market_rate?.toFixed(2)}
            </span>
          </div>
          <div className='field-right'>
            <p className='label'>{labels.home.rate_to_upload}</p>
            <RateOverrideField metrics={metrics} setDirty={setIsDirty} />{' '}
          </div>
        </div>
      </div>
    </CustomCard>
  );
};
