import './inventory-rate-level-widget.scss';

import { MenuItem, Select, SelectChangeEvent } from '@mui/material';

import { AgGridReact } from 'ag-grid-react';
import { CustomCard } from '../custom-card';
import { FormattedRateLevels } from 'pages/home/hooks/use-rate-levels-for-date';
import { ICellRendererParams } from 'ag-grid-community';
import { LiveDataField } from 'components/live-data-field';
import { LoadingBar } from 'components/loading-bar/loading-bar';
import { getPercentage } from 'helpers/math-helpers';
import { labels } from 'locales/en.label';

export type View = 'inventory' | 'rates';

export type InventoryData = {
  groupBlocked: number;
  groupBlockedLive: number | undefined;
  groupBooked: number;
  groupBookedLive: number | undefined;
  outOfOrder: number;
  outOfOrderLive: number | undefined;
  transientBooked: number;
  transientBookedLive: number | undefined;
  physicalCap: number;
};

type InventoryRateLevelWidgetProps = {
  view: View;
  setView: (view: View) => void;
  inventoryData: InventoryData | undefined;
  rateLevels: FormattedRateLevels | undefined;
  loadingMetrics: boolean;
  loadingRateLevels: boolean;
};

type RateLevelsProps = {
  rateLevels: FormattedRateLevels;
};

type BarProps = {
  value: number;
  liveValue?: number;
  className: string;
};

const Inventory = (props: InventoryData) => {
  const Bar = ({ value, liveValue, className }: BarProps) => (
    <div className='bar-container'>
      <div className='bar'>
        <div className='total'>
          <div
            className={className}
            style={{
              width: `${getPercentage(liveValue ?? value, props.physicalCap)}%`,
            }}
          />
        </div>
      </div>
      <LiveDataField
        liveVal={liveValue}
        oldVal={value}
        enableCurrencyFormatter={false}
      />
    </div>
  );

  return (
    <div className='inventory-container'>
      <div className='label'>{labels.home.inventory_labels.grp_blocked}</div>
      <Bar
        className='group-blocked'
        value={props.groupBlocked}
        liveValue={props.groupBlockedLive}
      />

      <div className='label'>{labels.home.inventory_labels.grp_booked}</div>
      <Bar
        className='group-booked'
        value={props.groupBooked}
        liveValue={props.groupBookedLive}
      />

      <div className='label'>{labels.home.inventory_labels.out_of_order}</div>
      <Bar
        className='out-of-order'
        value={props.outOfOrder}
        liveValue={props.outOfOrderLive}
      />

      <div className='label'>{labels.home.inventory_labels.transient}</div>
      <Bar
        className='transient-booked'
        value={props.transientBooked}
        liveValue={props.transientBookedLive}
      />

      <div className='label'>{labels.home.inventory_labels.phy_cap}</div>
      <Bar className='physical-cap' value={props.physicalCap} />
    </div>
  );
};

const CustomRenderer = ({ value }: ICellRendererParams) => value ?? '-';

const columns = [
  { field: 'level', headerName: labels.home.header_names.level },
  {
    field: 'liveOtb',
    headerName: labels.home.header_names.otb,
    cellRenderer: (params: ICellRendererParams) => (
      <LiveDataField
        liveVal={params.data?.liveOtb}
        oldVal={params.data?.otb}
        enableCurrencyFormatter={false}
      />
    ),
  },
  {
    field: 'fcstSell',
    headerName: labels.home.header_names.fcst,
    minWidth: 110,
    cellRenderer: CustomRenderer,
  },
  {
    field: 'status',
    headerName: labels.home.header_names.status,
    cellRenderer: CustomRenderer,
  },
];

const RateLevels = ({ rateLevels }: RateLevelsProps) => (
  <div className='ag-theme-alpine'>
    <AgGridReact
      rowData={rateLevels}
      columnDefs={columns}
      rowHeight={31}
      onGridReady={(e) => e.api.sizeColumnsToFit()}
      onGridSizeChanged={(e) => e.api.sizeColumnsToFit()}
      suppressCellFocus
      suppressMovableColumns
    />
  </div>
);

export const InventoryRateLevelWidget = ({
  view,
  setView,
  inventoryData,
  rateLevels,
  loadingMetrics,
  loadingRateLevels,
}: InventoryRateLevelWidgetProps) => {
  let isLoading = true;
  let hasData = false;
  let component = null;

  if (view === 'inventory') {
    isLoading = loadingMetrics;
    hasData = !!inventoryData;
    if (inventoryData) component = <Inventory {...inventoryData} />;
  }
  if (view === 'rates') {
    isLoading = loadingRateLevels;
    hasData = !!rateLevels;
    if (rateLevels) component = <RateLevels rateLevels={rateLevels} />;
  }

  return (
    <CustomCard className='inventory-rate-level-widget'>
      {isLoading ? (
        <LoadingBar />
      ) : (
        <div className='content'>
          <Select
            value={view}
            onChange={(e: SelectChangeEvent) => setView(e.target.value as View)}
            className='view-select'
          >
            <MenuItem value='inventory'>Inventory</MenuItem>
            <MenuItem value='rates'>Rate Level</MenuItem>
          </Select>
          {hasData ? (
            component
          ) : (
            <span className='no-data'>{labels.home.no_data}</span>
          )}
        </div>
      )}
    </CustomCard>
  );
};
