import './rec-rate-widget.scss';

import { Tooltip, capitalize } from '@mui/material';
import { priorityColors, toDollarAmountDecimals } from 'pages/home/helpers';

import { CustomCard } from '../custom-card';
import { FaCircle } from 'react-icons/fa';
import { IoTriangle } from 'react-icons/io5';
import { LiveDataField } from 'components/live-data-field';
import { LoadingBar } from 'components/loading-bar/loading-bar';
import { MetricsWithLiveData } from 'pages/home/hooks/use-prop-metrics';
import { RateOverrideField } from '../rate-override-field';
import _ from 'lodash';
import { formattedCurrency } from 'pages/MyView/tableCustomCellRender';
import { labels } from 'locales/en.label';
import styles from 'common/_variables.module.scss';
import { usePropertyContext } from 'context/propertyContext';
import { useUser } from 'features/users/context/userContext';

type RecRateWidgetProps = {
  metrics: MetricsWithLiveData | undefined;
  loading: boolean;
};

const diffColors = {
  '+': styles.colorGreen,
  '-': '#FD6F6F',
};

export const RecRateWidget = ({ metrics, loading }: RecRateWidgetProps) => {
  const { property, currencySymbol } = usePropertyContext();
  const { locale } = useUser();
  if (!metrics)
    return (
      <CustomCard className='rec-rate-widget'>
        <LoadingBar loading={loading} />
      </CustomCard>
    );

  const {
    rec_rate,
    priority,
    current_rate,
    current_rate_live,
    market_rate,
    adr,
    adr_live,
    adr_lyst,
  } = metrics;

  const rateDiff = Math.round(rec_rate - (current_rate_live ?? current_rate));
  let sign: '' | '+' | '-' = '';
  if (rateDiff < 0) sign = '-';
  if (rateDiff > 0) sign = '+';

  const currentAdr = adr_live ?? adr;
  let adrArrowType: string | null = null;
  if (adr_lyst < currentAdr) adrArrowType = 'up';
  if (adr_lyst > currentAdr) adrArrowType = 'down';

  return (
    <CustomCard className='rec-rate-widget'>
      <div className='content'>
        <div className='row'>
          <div className='section'>
            {!_.isNil(priority) && (
              <Tooltip
                title={`${capitalize(labels.common.rec_levels[priority])} ${
                  labels.common.priority
                }`}
                arrow
                placement='top'
                className='priority-icon'
              >
                <span>
                  <FaCircle color={priorityColors[priority]} size='1.25rem' />
                </span>
              </Tooltip>
            )}
            <h3>{labels.home.rec_rate}</h3>
          </div>
          <div className='section'>
            <RateOverrideField metrics={metrics} />
            <span
              className='rate-difference'
              style={{ color: sign ? diffColors[sign] : styles.mainGrey }}
            >
              ({sign}
              {currencySymbol}
              {Math.abs(rateDiff)})
            </span>
          </div>
        </div>
        <div className='row'>
          <div className='field'>
            <p className='label'>{labels.home.bw_rate}</p>
            <LiveDataField
              liveVal={toDollarAmountDecimals(current_rate_live)}
              oldVal={toDollarAmountDecimals(current_rate)}
              enableCurrencyFormatter={true}
            />
          </div>
          <hr className='divider' />
          <div className='field'>
            <p className='label'>{labels.home.market_rate}</p>
            <span>
              {formattedCurrency(
                market_rate?.toString(),
                locale,
                property?.currencyCode || 'USD',
                true
              )}
            </span>
          </div>
          <hr className='divider' />
          <div className='field'>
            <p className='label'>
              {labels.home.adr}
              {adrArrowType && (
                <IoTriangle className={`arrow ${adrArrowType}`} />
              )}
            </p>
            <LiveDataField
              liveVal={toDollarAmountDecimals(adr_live)}
              oldVal={toDollarAmountDecimals(adr)}
              enableCurrencyFormatter={true}
            />
          </div>
        </div>
      </div>
    </CustomCard>
  );
};
