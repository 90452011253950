import { z } from 'zod';

export const numberValidationSchema = (
  allowSigns: boolean,
  isFloat: boolean,
  minAmount: number,
  maxAmount: number
) => {
  const numberPattern = allowSigns
    ? isFloat
      ? /^[+-]?\d*\.?\d*$/
      : /^[+-]?\d*$/
    : isFloat
    ? /^\d*\.?\d*$/
    : /^\d+$/;

  return z
    .string()
    .regex(numberPattern, 'Invalid number format')
    .transform((val) => {
      if (val === '') return undefined;
      const num = parseFloat(val);
      if (isNaN(num)) return undefined;
      return num;
    })
    .refine(
      (val) => minAmount === undefined || val === undefined || val >= minAmount,
      `Value must be at least ${minAmount}`
    )
    .refine(
      (val) => maxAmount === undefined || val === undefined || val <= maxAmount,
      `Value must not exceed ${maxAmount}`
    );
};
