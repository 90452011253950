import './HelpLayout/HelpStyles.scss';

import { useEffect, useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { GetSupportArticlesByCategoryDocument } from 'features/help/gql/_gen_/help.gql';
import { LoadingBar } from 'components/loading-bar/loading-bar';
import useApolloErrorAlerts from 'features/alerts/hooks/use-apollo-error-alerts';
import { useQuery } from '@apollo/client';

interface Article {
  id: string;
  title: string;
  category: string;
  body: string;
  createdAt: string;
  updatedAt: string;
  slug: string;
}

interface GetArticlesData {
  getCategoryArticles: Article[];
}

export const Help = () => {
  const { category, slug } = useParams<{ category: string; slug?: string }>();
  const history = useHistory();

  const {
    data,
    loading,
    error: getArticlesError,
  } = useQuery<GetArticlesData>(GetSupportArticlesByCategoryDocument, {
    variables: {
      category,
    },
    skip: !category, // Skip the query if selectedCategory is null
  });

  useApolloErrorAlerts([getArticlesError]);

  // Sort the list of articles alphabetically
  const sortedArticles = useMemo(() => {
    return (
      data?.getCategoryArticles
        .slice()
        .sort((a, b) => a.title.localeCompare(b.title)) || []
    );
  }, [data]);

  const selectedArticle =
    slug === undefined
      ? null
      : sortedArticles.find((article) => article.slug === slug);

  // Set the selected article based on the slug in the URL
  useEffect(() => {
    if (!slug && sortedArticles.length > 0) {
      history.push(`/help/${category}/${sortedArticles[0].slug}`);
    }
  }, [sortedArticles, slug, category, history]);

  // Set the active button based on the selected category
  const handleCategoryClick = (newCategory: string) => {
    // If the selected category is already active, do nothing
    if (newCategory === category) return;
    history.push(`/help/${newCategory}`);
  };

  const handleArticleClick = (article: Article) => {
    history.push(`/help/${category}/${article.slug}`);
  };

  const handleEditClick = () => {
    if (selectedArticle) {
      history.push(`/help/edit/${selectedArticle.slug}`);
    }
  };

  return (
    <div className='help-container'>
      <div className='btns-container'>
        <button
          className={`articleButton ${category === 'general' ? 'active' : ''}`}
          onClick={() => handleCategoryClick('general')}
        >
          General
        </button>
        <button
          className={`articleButton ${category === 'help' ? 'active' : ''}`}
          onClick={() => handleCategoryClick('help')}
        >
          Glossary
        </button>
      </div>

      <LoadingBar
        className='help-content-container'
        loading={loading}
        overlayStyle={{ borderRadius: '10px' }}
      >
        <>
          {/* Conditionally render left column for any category except 'HELP' aka Glossary */}
          {category !== 'help' && (
            <div className='leftColumn'>
              {/* Left side: List of alphabetized article titles */}
              <ul>
                {sortedArticles.map((article) => (
                  <li
                    key={article.id}
                    onClick={() => handleArticleClick(article)}
                    className={selectedArticle === article ? 'selected' : ''}
                  >
                    {article.title}
                  </li>
                ))}
              </ul>
            </div>
          )}

          <div
            className={`rightColumn ${
              category === 'help' ? 'glossary-mode' : ''
            }`}
          >
            {selectedArticle && (
              <div>
                <h2>{selectedArticle.title}</h2>
                <div
                  dangerouslySetInnerHTML={{
                    __html: selectedArticle.body,
                  }}
                />
                <button className='editButton' onClick={handleEditClick}>
                  Edit
                </button>
              </div>
            )}
          </div>
        </>
      </LoadingBar>
    </div>
  );
};
