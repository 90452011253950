import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { useEffect, useState } from 'react';

import Alerts from 'components/alerts/alerts';
import { AppRoutes } from 'AppRoutes';
import ChatPanel from 'components/chat-panel/chat-panel';
import { Header } from 'components/Header/Header';
import HelpButton from 'components/HelpButton/help-button';
import { NavBar } from 'components/NavBar/NavBar';
import { alertClosed } from 'features/alerts/redux/alerts-slice';
import background from './images/background.png';
import useDeviceType from 'hooks/useDeviceType';
import { useLocation } from 'react-router-dom';
import { useUser } from 'features/users/context/userContext';

export const Main = () => {
  const dispatch = useAppDispatch();
  const { isAuthenticated } = useUser();
  const location = useLocation();
  const onHomePage = location.pathname.includes('home');
  const alerts = useAppSelector((state) => state.alerts);
  const { isTablet } = useDeviceType();
  const [chatOpen, toggleChat] = useState(false);
  useEffect(() => {
    if (isTablet) {
      const enforceLandscapeMode = () => {
        // @ts-ignore
        if (window.screen.orientation && window.screen.orientation?.lock) {
          // @ts-ignore
          window.screen.orientation?.lock('landscape');
          // @ts-ignore
        } else if (window.screen && window.screen?.lockOrientation) {
          // @ts-ignore
          window.screen?.lockOrientation('landscape');
        } else {
          // Display an error message or fallback behavior if locking fails
          console.error('Orientation locking not supported');
        }
      };
      enforceLandscapeMode();
      return () => {
        if (window.screen.orientation && window.screen.orientation.unlock) {
          window.screen.orientation.unlock();
          // @ts-ignore
        } else if (window.screen && window.screen.unlockOrientation) {
          // @ts-ignore
          window.screen.unlockOrientation();
        }
      };
    }
    return () => {};
  }, [isTablet]);

  return (
    <div
      className={`App ${onHomePage ? 'fixed-size' : ''}`}
      style={{
        backgroundImage: !isAuthenticated ? `url(${background})` : '',
      }}
    >
      <div className='left-container'>
        <NavBar />
      </div>
      <div className={`right-container ${isAuthenticated ? 'user' : ''}`}>
        <Alerts
          alerts={alerts}
          onAlertClose={(id) => dispatch(alertClosed(id))}
          duration={5}
        />
        <Header />
        <AppRoutes />
        <HelpButton toggleChat={toggleChat} />
        {chatOpen && <ChatPanel toggleChat={toggleChat} />}
      </div>
    </div>
  );
};
