import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { SearchInfo } from 'graphql/gql-types';

export enum MyViewTabsEnum {
  metrics = 'metrics',
  forecast = 'forecast',
  inventory = 'inventory',
  pickup = 'pickup',
  rates = 'rates',
  competitors = 'competitors',
  kpi = 'kpi',
}

interface TabObject extends Record<MyViewTabsEnum, string[] | undefined> {}

export interface MyViewState {
  myMetrics?: MetricColumn[];
  tabs?: Partial<TabObject>;
  queryInput: SearchInfo;
  tableSort?: TableSort;
  recReviewTab: string;
  recReviewChartDays: string;
  recReviewChartDayTypes?: string;
  showDataProcessingMessage?: boolean;
  showSearchPanel: boolean;
  searchId: number;
  recPriceChangeSearchId?: number;
}

export interface MetricColumn {
  field: string;
  source: string;
}

export interface TableSort {
  column: string;
  direction: 'asc' | 'desc' | null;
}

const initialState: MyViewState = {
  myMetrics: undefined,
  tabs: undefined,
  queryInput: {
    searchId: 1000,
    userId: undefined,
  },
  searchId: 1000,
  tableSort: undefined,
  recReviewTab: 'metrics',
  recReviewChartDays: '30',
  recReviewChartDayTypes: 'all',
  showDataProcessingMessage: false,
  showSearchPanel: false,
  recPriceChangeSearchId: undefined,
};

export const myViewSlice = createSlice({
  name: 'myView',
  initialState,
  reducers: {
    addColumn(state, action: PayloadAction<MetricColumn>) {
      if (!state.myMetrics) {
        state.myMetrics = [action.payload];
      } else {
        if (state.myMetrics.includes(action.payload)) return;
        state.myMetrics.unshift(action.payload);
      }
    },
    setMyMetrics(state, action: PayloadAction<MetricColumn[] | undefined>) {
      state.myMetrics = action.payload;
    },
    setTabs: (state, action: PayloadAction<Partial<TabObject> | undefined>) => {
      state.tabs = action.payload;
    },
    setQueryInput(state, action: PayloadAction<SearchInfo>) {
      state.queryInput = action.payload;
    },
    setSearchId(state, action: PayloadAction<number>) {
      state.searchId = action.payload;
    },
    setRecPriceChangeSearchId(
      state,
      action: PayloadAction<number | undefined>
    ) {
      state.recPriceChangeSearchId = action.payload;
    },
    setShowSearchPanel(state, action: PayloadAction<boolean>) {
      state.showSearchPanel = action.payload;
    },
    setShowDataProcessingMessage(state, action: PayloadAction<boolean>) {
      state.showDataProcessingMessage = action.payload;
    },
    setTableSort(state, action: PayloadAction<TableSort>) {
      state.tableSort = action.payload;
    },
    removeColumn(state, action: PayloadAction<string>) {
      if (state.myMetrics && state.myMetrics.length) {
        state.myMetrics = state.myMetrics.filter(
          (metric) => metric.field !== action.payload
        );
      }
    },
    setRecReviewTab(state, action: PayloadAction<string>) {
      state.recReviewTab = action.payload;
    },
    setRecReviewChartDays(state, action: PayloadAction<string>) {
      state.recReviewChartDays = action.payload;
    },
    setRecReviewChartDayTypes(state, action: PayloadAction<string>) {
      state.recReviewChartDayTypes = action.payload;
    },
  },
});

export const {
  addColumn,
  removeColumn,
  setMyMetrics,
  setTabs,
  setQueryInput,
  setSearchId,
  setTableSort,
  setRecReviewTab,
  setRecReviewChartDays,
  setRecReviewChartDayTypes,
  setShowDataProcessingMessage,
  setShowSearchPanel,
  setRecPriceChangeSearchId,
} = myViewSlice.actions;

export default myViewSlice.reducer;
