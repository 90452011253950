import './rate-upload-button.scss';

import { IoMdArrowDropdown, IoMdArrowDropup } from 'react-icons/io';
import {
  formattedCurrency,
  twoDecimalRateFormatter,
} from 'pages/MyView/tableCustomCellRender';

import { DiJqueryLogo } from 'react-icons/di';
import IconButton from '@mui/material/IconButton';
import { MdOutlineFileUpload } from 'react-icons/md';
import { Modal } from 'components/Modal/Modal';
import Tooltip from '@mui/material/Tooltip';
import { formatDateWithGivenFormatter } from 'features/dates/date-helpers';
import { labels } from 'locales/en.label';
import { selectPendingRecs } from '../redux/rate-upload-selectors';
import { selectProperty } from 'features/property/redux/selectors';
import styles from '../../../common/_variables.module.scss';
import { useAppSelector } from 'redux/hooks';
import { useState } from 'react';
import { useUser } from 'features/users/context/userContext';

interface RateUploadButtonProps {
  /**
   * Whether the button is disabled or not
   */
  disabled: boolean;
  /**
   * Function that triggers the rate upload process
   * @returns a promise that resolves to the SNS message ID
   */
  handleTriggerUpload: () => Promise<any>;
  /**
   * Whether we are polling for Rec Rate updates after an upload
   */
  polling: boolean;
  /**
   * Marked as true while we are sending the request to SNS
   */
  uploading: boolean;
  /**
   * Optional className for the IconButton
   */
  className?: string;
}

export const RateUploadButton = ({
  disabled,
  handleTriggerUpload,
  polling,
  uploading,
  className,
}: RateUploadButtonProps) => {
  const { currencyCode } = useAppSelector(selectProperty);
  const pendingRecs = useAppSelector(selectPendingRecs);
  const { locale } = useUser();
  const [isShown, setIsShown] = useState(false);
  const toggle = () => setIsShown(!isShown);

  return (
    <>
      <Modal
        className='rate-upload-modal'
        isShown={isShown}
        hide={toggle}
        onCancel={toggle}
        headerText={labels.rate_upload.header}
        messageBody={
          <div className='rates-confirmation'>
            <p>{labels.rate_upload.verify_msg}</p>
            <ul>
              <li>
                <div>{'Stay \n Date'}</div>
                <div>{'Rec \nRate'}</div>
                <div>{'BWH \nRate'}</div>
                <div>{'Overridden \nRate'}</div>
              </li>
            </ul>
            <ul>
              {pendingRecs?.map((rec) => (
                <li key={rec.stay_date}>
                  <div>
                    {formatDateWithGivenFormatter(rec.stay_date, 'M/DD/YY')}
                  </div>
                  <div>
                    {formattedCurrency(
                      rec.rec_rate?.toString() || '0',
                      locale,
                      currencyCode,
                      true,
                      twoDecimalRateFormatter
                    )}
                  </div>
                  <div>
                    {formattedCurrency(
                      rec.current_rate?.toString() || '0',
                      locale,
                      currencyCode,
                      true,
                      twoDecimalRateFormatter
                    )}
                  </div>
                  <div>
                    {formattedCurrency(
                      rec.overriden_rate?.toString() || '0',
                      locale,
                      currencyCode,
                      true,
                      twoDecimalRateFormatter
                    )}
                  </div>
                  <div>
                    {rec.overriden_rate! > rec.current_rate! ? (
                      <IoMdArrowDropup size={20} color={styles.colorGreen} />
                    ) : rec.overriden_rate! < rec.current_rate! ? (
                      <IoMdArrowDropdown
                        size={20}
                        color={styles.colorWarning}
                      />
                    ) : (
                      <span>{''}</span>
                    )}
                  </div>
                </li>
              ))}
            </ul>
          </div>
        }
        isConfirmationModal
        confirmBtnText={labels.rate_upload.confirm_btn}
        onConfirm={() => {
          handleTriggerUpload();
          toggle();
        }}
      />
      <Tooltip title={labels.rate_upload.tooltip_title} arrow placement='top'>
        <span>
          <IconButton
            disableFocusRipple
            disableRipple
            aria-label='upload'
            className={className}
            onClick={toggle}
            disabled={disabled || polling || uploading}
          >
            {polling ? (
              <DiJqueryLogo className='loader' />
            ) : (
              <MdOutlineFileUpload
                className={uploading ? 'loader' : undefined}
              />
            )}
          </IconButton>
        </span>
      </Tooltip>
    </>
  );
};
