import './App.css';

import { persistor, store } from './redux/store';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { ApolloProviderWrapper } from 'apollo/apollo-provider-wrapper';
import { DefaultProvider } from 'features/default/context/defaultContext';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Main } from 'main';
import { PersistGate } from 'redux-persist/integration/react';
import { PropertyProvider } from 'context/propertyContext';
import { Provider as ReduxProvider } from 'react-redux';
import { Toaster } from 'react-hot-toast';
import { UserProvider } from './features/users/context/userContext';
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import { setUseWhatChange } from '@simbathesailor/use-what-changed';

dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

setUseWhatChange(process.env.NODE_ENV === 'development');

export const App = () => {
  return (
    <ReduxProvider store={store}>
      <ApolloProviderWrapper>
        <UserProvider>
          <PersistGate loading={null} persistor={persistor}>
            <PropertyProvider>
              <DefaultProvider>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Main />
                  <Toaster />
                </LocalizationProvider>
              </DefaultProvider>
            </PropertyProvider>
          </PersistGate>
        </UserProvider>
      </ApolloProviderWrapper>
    </ReduxProvider>
  );
};
