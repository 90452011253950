import { TrackingEnum, TrackingInput } from '../types/TrackingTypes';

import { datalakeIngestion } from 'helpers/datalakeHelper';
import { selectProperty } from '../features/property/redux/selectors';
import { useAppSelector } from 'redux/hooks';
import { useLogTrackingMutation } from 'features/tracking/gql/_gen_/tracking.gql';
import { useOktaAuth } from '@okta/okta-react';
import { useUser } from '../features/users/context/userContext';

export const useAppTracking = () => {
  const { user } = useUser();
  const property: { propertyId: string } = useAppSelector(selectProperty);
  const [logTracking] = useLogTrackingMutation();
  const { authState } = useOktaAuth();
  const accessToken = authState?.accessToken?.accessToken;

  const getUserFields = () => {
    if (!user?.email || !user?.id || !property?.propertyId) return null;
    return {
      oktaId: user?.okta_id,
      userName: user?.name,
      userEmail: user?.email,
      userRole: user?.role,
      userId: user.id,
    };
  };

  const track = (
    payload: TrackingInput,
    trackingEnum: TrackingEnum = TrackingEnum.PG_DB
  ) => {
    const userFields = getUserFields();
    if (userFields) {
      const tracking = {
        ...userFields,
        ...payload,
        propertyId: payload.propertyId || property?.propertyId,
      };

      if (trackingEnum === TrackingEnum.PG_DB)
        logTracking({
          variables: { tracking },
        });

      const dataLakeTracking = { ...tracking, trackingEnum };
      datalakeIngestion(window, dataLakeTracking, accessToken)
        .then()
        .catch((error) => console.log(error));
    }
  };

  return {
    track,
  };
};
