import './help-button.scss';

import { Menu, MenuItem } from '@mui/material';

import { FaRegQuestionCircle } from 'react-icons/fa';
import { Modal } from 'components/Modal/Modal';
import { labels } from 'locales/en.label';
import useDeviceType from 'hooks/useDeviceType';
import { useState } from 'react';

interface HelpButtonProps {
  toggleChat?: React.Dispatch<React.SetStateAction<boolean>>;
}

const HelpButton = ({ toggleChat }: HelpButtonProps) => {
  const { isMobile } = useDeviceType();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(anchorEl);
  const closeMenu = () => setAnchorEl(null);

  return (
    <div>
      <button
        className='help-button'
        onClick={(e) => setAnchorEl(e.currentTarget)}
      >
        <div className='help-button__icon'>
          <FaRegQuestionCircle size={`${isMobile ? '13px' : '18px'}`} />
        </div>
        <span className='help-button__text'>Help</span>
      </button>
      <Menu
        anchorEl={anchorEl}
        open={isMenuOpen}
        onClose={closeMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <MenuItem
          onClick={(e) => {
            window.location.href = `mailto:${labels.app.support_email}`;
            e.preventDefault();
            closeMenu();
          }}
        >
          Email Support
        </MenuItem>
        <MenuItem
          onClick={() => {
            setIsModalOpen(true);
            closeMenu();
          }}
        >
          Reset BestRev Data
        </MenuItem>
        <MenuItem
          onClick={() => {
            toggleChat && toggleChat(true);
            closeMenu();
          }}
        >
          {`Chat with ${labels.chat.title}`}
        </MenuItem>
      </Menu>
      <Modal
        isShown={isModalOpen}
        hide={() => setIsModalOpen(false)}
        isConfirmationModal
        headerText='Are You Sure?'
        cancelBtnText='Cancel'
        confirmBtnText='Reset'
        onConfirm={() => {
          localStorage.clear();
          window.location.reload();
        }}
        onCancel={() => setIsModalOpen(false)}
      >
        Resetting BestRev data will clear stored settings and log you out. Are
        you sure you want to proceed?
      </Modal>
    </div>
  );
};

export default HelpButton;
