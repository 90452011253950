import { IHeaderParams } from 'ag-grid-community';
import { IconType } from 'react-icons/lib';
import Tooltip from '@mui/material/Tooltip';
import { labels } from 'locales/en.label';
import { useDraggable } from '@dnd-kit/core';

export type HeaderActionType = 'add' | 'added' | 'remove';

export interface ICustomHeaderParams extends IHeaderParams {
  headerIcon: string | IconType;
  actionType?: HeaderActionType;
  onClick?: () => void; // on Plus/Minus click
}

export const CustomHeader = ({
  headerIcon,
  column,
  setSort,
  enableSorting,
  displayName,
  actionType,
  onClick,
}: ICustomHeaderParams) => {
  const field = column.getColId();

  const { attributes, listeners, setNodeRef } = useDraggable({
    id: column.getColId(),
    data: {
      action: actionType === 'add' ? 'add' : null,
      // @ts-ignore
      source: column.getColDef().source,
    },
  });

  const onSortRequested = (order: 'asc' | 'desc', event: any) => {
    setSort(order as any, event.shiftKey);
  };

  let columnIcon = null;
  if (headerIcon) {
    columnIcon = (
      <div className='customHeader__column-icon'>
        {typeof headerIcon === 'string' ? (
          <i className={`fa ${headerIcon}`} />
        ) : (
          headerIcon
        )}
      </div>
    );
  }

  let sort = null;
  if (enableSorting) {
    sort = (
      <div className='customHeader__name'>
        {headerIcon === undefined && <span>{displayName}</span>}
        {column.isSortAscending() && (
          <span className={`sortDownArrow`}>
            <i className='fa fa-sort-up'></i>
          </span>
        )}
        {column.isSortDescending() && (
          <span className={`sortUprrow`}>
            <i className='fa fa-sort-down'></i>
          </span>
        )}
      </div>
    );
  } else {
    sort = (
      <div className='customHeader__name'>
        {headerIcon === undefined && <span>{displayName}</span>}
      </div>
    );
  }

  const handleClick = (event: any) => {
    if (column.isSortAscending()) {
      onSortRequested('desc', event);
    } else {
      onSortRequested('asc', event);
    }
  };

  let actionIcon: JSX.Element | null = null;
  if (actionType) {
    const tooltipMessages = {
      add: labels.rec_review.dashboard.more_action.add_column_to_metric,
      added: labels.rec_review.dashboard.more_action.column_added,
      remove: labels.rec_review.dashboard.more_action.remove_column_from_metric,
    };
    const iconNames = {
      add: 'fa-plus',
      added: 'fa-check',
      remove: 'fa-minus',
    };

    actionIcon = (
      <Tooltip title={tooltipMessages[actionType]} arrow placement='top'>
        <div
          id={field}
          className={`icon-${actionType}`}
          onClick={onClick}
          data-column={field}
        >
          <i className={`fa ${iconNames[actionType]}`}></i>
        </div>
      </Tooltip>
    );
  }

  return (
    <>
      <div
        ref={setNodeRef}
        {...listeners}
        {...attributes}
        className='customHeader'
        onClick={(e) => handleClick(e)}
      >
        {actionIcon}
        <div className='customHeaderLabel'>
          {columnIcon}
          {sort}
        </div>
      </div>
    </>
  );
};
