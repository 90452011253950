import './forecast-info-tooltip.scss';

import { FaLongArrowAltDown, FaLongArrowAltUp } from 'react-icons/fa';
import { Tooltip, TooltipProps, styled, tooltipClasses } from '@mui/material';

import { LoadingBar } from 'components/loading-bar/loading-bar';
import { Maybe } from 'types/maybe-type';
import dayjs from 'dayjs';
import { labels as globalLabels } from 'locales/en.label';
import styles from 'common/_variables.module.scss';
import { usePropertyContext } from 'context/propertyContext';

const labels = globalLabels.rec_review.dashboard.forecast_info;

interface RecRateExplainer {
  newRecRate: Maybe<number>;
  prevRecRate: Maybe<number>;
  currentRate: Maybe<number>;
  pricingRatio: Maybe<number>;
  pickupRatio: Maybe<number>;
  incrementalRevenue: Maybe<number>;
  changeInRecRate: Maybe<number>;
  changeInMarketRate: Maybe<number>;
  reasons: Maybe<string>;
  lastHourPickup: Maybe<number>;
  expectedPickup: Maybe<number>;
  actualPickup: Maybe<number>;
  expectedAllDayPickup: Maybe<number>;
  expectedAllDayPickupUnconstrained: Maybe<number>;
  snapshotHour: number;
  lastUpdatedDate: string;
}

interface ForecastInfoTooltipProps {
  data: Maybe<RecRateExplainer>;
  loading?: boolean;
  children: React.ReactElement<any, any> & React.ReactNode;
}

type ReasonCodes = '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8';

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip arrow {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.arrow}`]: {
    '&::before': {
      color: styles.whiteColor,
      border: `1px solid #ccc`,
    },
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: styles.whiteColor,
    border: `1px solid #ccc`,
    padding: 0,
    maxWidth: 'none',
  },
}));

export const ForecastInfoTooltip = ({
  data,
  loading = false,
  children,
}: ForecastInfoTooltipProps) => {
  const { currencySymbol } = usePropertyContext();
  const recVariance =
    data?.newRecRate && data?.currentRate
      ? data?.newRecRate - data?.currentRate
      : 0;
  let changeType: '' | 'inc' | 'dec' = '';

  if (recVariance > 0) {
    changeType = 'inc';
  } else if (recVariance < 0) {
    changeType = 'dec';
  }

  const reasons = (data?.reasons?.split(',') ?? []) as ReasonCodes[];

  const pricingRatio = data?.pricingRatio ?? 1;
  const pickupRatio = data?.pickupRatio ?? 1;

  const updatedAt = dayjs()
    .startOf('day')
    .add(data?.snapshotHour ?? 0, 'hours')
    .format('M/D hh:mma');

  const formatIncRev = (value: Maybe<number>) => {
    if (value !== null && value !== undefined) {
      return Number.isInteger(value) ? value : value.toFixed(2);
    }
  };

  return (
    <StyledTooltip
      title={
        <LoadingBar className='forecast-info-tooltip' loading={loading}>
          <div className='content'>
            <h1>Pricing Recommendation</h1>
            <h2 className={`recommendation ${changeType}`}>
              {labels.header(changeType, Math.abs(recVariance))}
            </h2>
            <p className='last-updated'>last updated: {updatedAt}</p>
            <div className='body'>
              <div className='rates'>
                <div className='rate-container'>
                  <h3>{labels.current}</h3>
                  <span className='value'>
                    {currencySymbol}
                    {data?.currentRate}
                  </span>
                </div>
                <div className='rate-container'>
                  <h3>{labels.prev_rec_rate}</h3>
                  <span className='value'>
                    {currencySymbol}
                    {data?.prevRecRate}
                  </span>
                </div>
                <div className='rate-container'>
                  <h3>{labels.recommended}</h3>
                  <span className='value'>
                    {currencySymbol}
                    {data?.newRecRate}
                  </span>
                </div>
              </div>

              <div className='ratios'>
                <div className='row'>
                  <span>{labels.pricing_ratio}</span>
                  <div className='badge'>
                    <span className={pricingRatio > 1 ? 'up' : 'down'}>
                      {pricingRatio > 1 ? (
                        <FaLongArrowAltUp />
                      ) : pricingRatio < 1 ? (
                        <FaLongArrowAltDown />
                      ) : null}
                    </span>
                    <span>{data?.pricingRatio}</span>
                  </div>
                </div>
                <div className='row'>
                  <span>{labels.pickup_ratio}</span>
                  <div className='badge'>
                    <span className={pickupRatio > 1 ? 'up' : 'down'}>
                      {pickupRatio > 1 ? (
                        <FaLongArrowAltUp />
                      ) : pickupRatio < 1 ? (
                        <FaLongArrowAltDown />
                      ) : null}
                    </span>
                    <span>{data?.pickupRatio}</span>
                  </div>
                </div>
                {data?.incrementalRevenue !== null && (
                  <div className='row'>
                    <span>{labels.incremental_revenue}</span>
                    <div className='badge'>
                      <span>
                        {currencySymbol}{' '}
                        {formatIncRev(data?.incrementalRevenue)}
                      </span>
                    </div>
                  </div>
                )}
              </div>

              <div className='pickup'>
                <h2>{labels.pickup}</h2>
                <div className='row'>
                  <div>
                    <span>{labels.last_hour}: </span>
                    <span className='value'>{data?.lastHourPickup}</span>
                  </div>

                  <div>
                    <span>{labels.remaining}: </span>
                    <span className='value'>{data?.expectedAllDayPickup}</span>
                  </div>
                </div>

                <h4>{labels.since_12}</h4>
                <div className='row'>
                  <div className='row'>
                    <span>{labels.expected}: </span>
                    <span className='value'>{data?.expectedPickup}</span>
                  </div>
                  <div className='row'>
                    <span>{labels.actual}: </span>
                    <span className='value'>{data?.actualPickup}</span>
                  </div>
                </div>
              </div>

              <div className='reasons'>
                <h2>{labels.reasons}</h2>
                <ul>
                  {reasons?.map((code) => (
                    <li key={code}>{labels.reason_codes[code]}</li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </LoadingBar>
      }
      placement='right'
    >
      {children}
    </StyledTooltip>
  );
};

export default ForecastInfoTooltip;
