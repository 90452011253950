import axios from 'axios';
import { windowDataExtractor } from './trackingHelper';
import { TrackingInput } from 'types/TrackingTypes';

export const datalakeIngestion = async (
  window: Window,
  trackingData: TrackingInput,
  token?: string
) => {
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  };
  const clientData = windowDataExtractor(window);
  const { value, ...restData } = trackingData;

  return await axios.post(
    process.env.REACT_APP_DATA_LAKE_API_URL! + '/data-stream',
    { ...restData, ...clientData, value: value ? JSON.parse(value) : '' },
    { headers }
  );
};
