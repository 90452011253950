import './video-uploader.scss';

import { Button, Tooltip, styled } from '@mui/material';
import { CloudUpload, InfoOutlined } from '@mui/icons-material';
import { ColDef, ICellRendererParams } from 'ag-grid-community';

import { AgGridReact } from 'ag-grid-react';
import { Link } from 'react-router-dom';
import { LoadingBar } from 'components/loading-bar/loading-bar';
import { Video } from 'graphql/gql-types';
import { alertAdded } from 'features/alerts/redux/alerts-slice';
import axios from 'axios';
import { upperFirst } from 'lodash';
import useApolloErrorAlerts from 'features/alerts/hooks/use-apollo-error-alerts';
import { useAppDispatch } from 'redux/hooks';
import { useGetVideoQueueQuery } from 'features/admin/gql/_gen_/admin.gql';
import { useOktaAuth } from '@okta/okta-react';
import { useState } from 'react';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const columns: ColDef[] = [
  { field: 'title', headerName: 'File' },
  {
    field: 'status',
    headerName: 'Status',
    cellRenderer: ({ data, value }: ICellRendererParams<Video>) => {
      const message = data?.errorMessage
        ? `Error Message: ${data.errorMessage}`
        : undefined;
      return (
        <Tooltip title={message}>
          <div className='status-cell'>
            {upperFirst(value)}
            {message && <InfoOutlined className='info-icon' />}
          </div>
        </Tooltip>
      );
    },
  },
];

export const VideoUploader = () => {
  const dispatch = useAppDispatch();
  const { authState } = useOktaAuth();
  const token = authState?.accessToken?.accessToken;
  const { data, loading, error } = useGetVideoQueueQuery();
  const [file, setFile] = useState<File>();
  const [uploading, setUploading] = useState(false);

  useApolloErrorAlerts([error]);

  const handleSubmit = async () => {
    if (!file) return;

    setUploading(true);

    try {
      // Get pre-signed URL
      const { data } = await axios.get(
        `${process.env.REACT_APP_BEDROCK_API_URL}/get-presigned-url`,
        {
          params: { fileName: file.name, fileType: file.type },
        }
      );

      // Upload to S3
      await axios.put(data.signedUrl, file, {
        headers: { 'Content-Type': file.type },
      });

      const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      };

      await axios.post(
        `${process.env.REACT_APP_BEDROCK_API_URL}/transcribe-video`,
        null,
        {
          headers,
          params: { name: file.name },
        }
      );

      setFile(undefined);
      dispatch(alertAdded('success', 'File uploaded successfully!'));
    } catch (error) {
      console.error('Error uploading file:', error);
      dispatch(alertAdded('error', 'Error uploading file'));
    }

    setUploading(false);
  };

  return (
    <div className='video-uploader-container'>
      <LoadingBar loading={uploading}>
        <div className='video-uploader-content'>
          <div className='row'>
            <Link to='/admin'>
              <button>Back</button>
            </Link>
          </div>
          <div className='row'>
            <h3>Add videos to RevBot knowledge base</h3>
          </div>
          <div className='row'>
            <Button
              component='label'
              variant='contained'
              tabIndex={-1}
              startIcon={<CloudUpload />}
              className='upload-button'
            >
              Upload file
              <VisuallyHiddenInput
                type='file'
                onChange={(e) => setFile(e.target.files?.[0])}
              />
            </Button>
            <span className='file-selection'>
              {file?.name || 'No file chosen'}
            </span>
            <button
              onClick={handleSubmit}
              className='submit-button'
              disabled={!file}
            >
              Submit
            </button>
          </div>
          <LoadingBar
            className='ag-theme-alpine'
            loading={loading}
            overlayStyle={{ borderRadius: '10px' }}
          >
            <AgGridReact
              rowData={data?.getVideoQueue || []}
              columnDefs={columns}
              onGridReady={(e) => e.api.sizeColumnsToFit()}
              onGridSizeChanged={(e) => e.api.sizeColumnsToFit()}
              suppressCellFocus
              suppressMovableColumns
            />
          </LoadingBar>
        </div>
      </LoadingBar>
    </div>
  );
};
