import './Admin.scss';

import { useEffect, useState } from 'react';
import {
  useGetControlPanelQuery,
  useSetSupportMessageMutation,
  useToggleAutopilotMutation,
} from 'features/control-panel/gql/_gen_/control-panel.gql';
import {
  useGetMigrationsQuery,
  useGetPropBestRevStatusQuery,
  useRunMigrationsMutation,
  useSetPropBestRevStatusMutation,
} from 'features/admin/gql/_gen_/admin.gql';

import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import { FaPlayCircle } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { LoadingBar } from 'components/loading-bar/loading-bar';
import { ToggleButton } from 'components/ToggleButton/ToggleButton';
import { selectProperty } from 'features/property/redux/selectors';
import { setSupportMessage } from 'features/header/redux/header-slice';
import { useApolloError } from 'hooks/useApolloError';
import { useAppDispatch } from 'redux/hooks';
import { useAppSelector } from 'redux/hooks';

export function Admin() {
  const [autopilotStatus, setautopilotStatus] = useState(false);
  const [bestRevStatus, setBestRevStatus] = useState(false);
  const [supportMessage, setMessage] = useState('');
  const property = useAppSelector(selectProperty);
  const propertyId = property.propertyId;

  const dispatch = useAppDispatch();
  const { handleApolloError } = useApolloError();

  const { data: controlPanelData, loading: loadingControlPanel } =
    useGetControlPanelQuery();

  const supportMessageData = controlPanelData?.getControlPanel?.support_message;
  const saveButtonDisplayed = !supportMessageData
    ? supportMessage.length
      ? true
      : false
    : supportMessage !== supportMessageData
    ? true
    : false;

  useEffect(() => {
    if (controlPanelData && controlPanelData.getControlPanel) {
      if (controlPanelData.getControlPanel.allow_autopilot) {
        setautopilotStatus(true);
      } else {
        setautopilotStatus(false);
      }
      if (controlPanelData.getControlPanel.support_message) {
        setMessage(controlPanelData.getControlPanel.support_message);
      }
    }
  }, [controlPanelData]);

  const [toggleAutopilot] = useToggleAutopilotMutation({
    refetchQueries: ['GetControlPanel'],
    onError: (error) => {
      if (
        typeof controlPanelData?.getControlPanel?.allow_autopilot === 'boolean'
      ) {
        setautopilotStatus(controlPanelData?.getControlPanel?.allow_autopilot);
      }
      handleApolloError(error);
    },
  });

  const [saveSupportMessage] = useSetSupportMessageMutation({
    refetchQueries: ['GetControlPanel'],
    variables: {
      message: supportMessage,
    },
    onCompleted: (data) => {
      if (data?.setSupportMessage?.support_message) {
        dispatch(setSupportMessage(data.setSupportMessage.support_message));
      }
    },
    onError: (error) => {
      handleApolloError(error);
    },
  });

  const { data: bestRevStatusData, loading: loadingBestRevStatus } =
    useGetPropBestRevStatusQuery({
      variables: {
        propertyId,
      },
      fetchPolicy: 'network-only',
    });

  const [setBestRevActive] = useSetPropBestRevStatusMutation({
    onError: (error) => {
      if (
        typeof bestRevStatusData?.getPropertyByPropertyId?.bestrev_active ===
        'boolean'
      ) {
        setBestRevStatus(
          bestRevStatusData?.getPropertyByPropertyId?.bestrev_active
        );
      }
      handleApolloError(error);
    },
  });

  useEffect(() => {
    if (
      typeof bestRevStatusData?.getPropertyByPropertyId?.bestrev_active ===
      'boolean'
    ) {
      setBestRevStatus(
        bestRevStatusData?.getPropertyByPropertyId?.bestrev_active
      );
    }
  }, [bestRevStatusData]);

  const { data: migrationsData } = useGetMigrationsQuery();

  const [runMigrations, { loading: migrationLoading }] =
    useRunMigrationsMutation({
      refetchQueries: ['GetMigrations'],
    });

  const unexecutedMigrations = migrationsData?.getMigrations?.filter(
    (m) => !m?.executedAt
  );

  const migrationNames = unexecutedMigrations?.map((x) => {
    return x?.name?.split('_').slice(1).join('_');
  });

  const handleAutopilotToggle = () => {
    setautopilotStatus((prev) => !prev);
    toggleAutopilot();
  };

  const handleBestRevToggle = () => {
    setBestRevActive({
      refetchQueries: ['GetPropBestRevStatus'],
      variables: {
        propertyId,
        status: !bestRevStatus,
      },
    });
    setBestRevStatus((prev) => !prev);
  };

  const handleSupportMessage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMessage(event.target.value);
  };

  const handleSaveSupportMessage = () => {
    saveSupportMessage({
      refetchQueries: ['GetControlPanel'],
      variables: {
        message: supportMessage,
      },
    });
  };

  const clearSupportMessage = () => {
    setMessage('');
    saveSupportMessage({
      refetchQueries: ['GetControlPanel'],
      variables: {
        message: '',
      },
    });
    dispatch(setSupportMessage(''));
  };

  const singularMessage = `The following migration has not been run`;
  const pluralMessage = `The following ${unexecutedMigrations?.length} migrations have not been run. This may result in database errors`;

  return (
    <div className='admin-container'>
      <LoadingBar loading={loadingControlPanel}>
        <div className='admin-field'>
          <label>Autopilot</label>
          <div className='autopilot-toggle'>
            <ToggleButton
              id='autopilot'
              selected={autopilotStatus}
              labelOptions={['Off', 'On']}
              toggleSelected={handleAutopilotToggle}
            />
          </div>
        </div>
      </LoadingBar>
      <LoadingBar loading={loadingBestRevStatus}>
        <div className='admin-field'>
          <label>{`BestRev for Property: ${propertyId}`}</label>
          <div className='bestrev-toggle'>
            <ToggleButton
              id='bestrev'
              selected={bestRevStatus}
              labelOptions={['Off', 'On']}
              toggleSelected={handleBestRevToggle}
            />
          </div>
        </div>
      </LoadingBar>
      <LoadingBar loading={loadingControlPanel}>
        <div className='admin-field'>
          <label>Support Message</label>
          <input
            type='text'
            className='support-message-input'
            value={supportMessage}
            onChange={handleSupportMessage}
          />
          {saveButtonDisplayed && (
            <button className='save' onClick={() => handleSaveSupportMessage()}>
              Save
            </button>
          )}
          {controlPanelData?.getControlPanel?.support_message && (
            <button className='clear' onClick={clearSupportMessage}>
              Clear Message
            </button>
          )}
        </div>
      </LoadingBar>
      {!!unexecutedMigrations?.length &&
        process.env.NODE_ENV !== 'production' && (
          <div className='migrations'>
            <p>
              {unexecutedMigrations?.length > 1
                ? pluralMessage
                : singularMessage}
              . This may result in database errors.
            </p>
            <ul>
              {migrationNames?.map((migration) => (
                <li key={migration}>{migration}</li>
              ))}
            </ul>
            <button
              onClick={() => runMigrations()}
              className='migrations-button'
              disabled={migrationLoading}
            >
              <span>Run Migrations</span>
              {migrationLoading ? (
                <AiOutlineLoading3Quarters
                  style={{
                    animation: 'spin 1s linear infinite',
                  }}
                />
              ) : (
                <FaPlayCircle />
              )}
            </button>
          </div>
        )}
      <div className='links'>
        <Link to={'/help/create'}>
          <button>Submit an Article</button>
        </Link>
        <Link to={'/admin/video-uploader'}>
          <button>Add to RevBot KB</button>
        </Link>
      </div>
    </div>
  );
}
