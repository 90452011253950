import { addDays, dataDate, dateFormatData } from 'features/dates/date-helpers';

import { GroupPriceToolInput } from 'pages/groups/group-price-tool';
import { NetBenefitData } from 'pages/groups/group-price-tool';

export const calculateForecastADR = (
  fcstRev: number,
  totalFcst: number,
  override: number
) => {
  const overridePercent = override / 100;
  const forecastADR = parseInt((fcstRev / totalFcst).toFixed(2));
  if (isNaN(forecastADR)) return 0;
  if (overridePercent === 0) return forecastADR;
  const adjustment = parseInt((forecastADR * overridePercent).toFixed(2));
  return forecastADR + adjustment;
};

export const calculateRoomsDisplaced = (
  capacity: number,
  newRoomsRequested: number,
  totalFcst: number
) => {
  if (capacity > newRoomsRequested + totalFcst) {
    return 0;
  }
  const totalRooms = newRoomsRequested + totalFcst;
  return totalRooms - capacity;
};

export const calculateGuestsWalked = (
  capacity: number,
  newRoomsRequested: number,
  currentOtb: number
) => {
  const totalRooms = newRoomsRequested + currentOtb;

  if (capacity > totalRooms) {
    return 0;
  }

  return totalRooms - capacity;
};

export const calculateNetRoomsAdded = (
  capacity: number,
  newRoomsRequested: number,
  transientFcst: number,
  washFactor: number
) => {
  const washedRooms = Math.round(newRoomsRequested * (washFactor / 100));
  const actualRooms = newRoomsRequested - washedRooms;
  if (capacity > actualRooms + transientFcst) {
    return actualRooms;
  }

  const roomsDisplaced = calculateRoomsDisplaced(
    capacity,
    newRoomsRequested,
    transientFcst
  );

  return actualRooms - roomsDisplaced;
};

export const calculateIncrementalRoomRevenue = (
  netRoomsAdded: number,
  rate_requested: number
) => {
  return netRoomsAdded * rate_requested;
};

export const calculateDisplacementCost = (
  roomsDisplaced: number,
  transientRate: number,
  rate_requested: number
) => {
  return roomsDisplaced * (transientRate - rate_requested) * -1;
};

export const calculateIncrementalCommission = (
  roomsDisplaced: number,
  fcstADR: number,
  commission: number,
  rate_requested: number
) => {
  const groupCommission = commission / 100;
  const standardCommission = 0.07;
  const incrementalCommission =
    (groupCommission * rate_requested - standardCommission * fcstADR) *
    roomsDisplaced;
  return roomsDisplaced ? incrementalCommission : 0;
};

export const calculateRequiredProfit = (
  rooms_requested: number,
  rate_requested: number,
  profitMargin: number
) => {
  const profitPercentage = profitMargin / 100;
  return rooms_requested * rate_requested * profitPercentage;
};

export const calculateOverride = (
  original: number,
  occFcstOverride: number
) => {
  const adjustmentPercent = occFcstOverride / 100;
  const adjustmentAmount = original * adjustmentPercent;
  return adjustmentAmount < 0
    ? Math.floor(original + adjustmentAmount)
    : Math.ceil(original + adjustmentAmount);
};

export const calculateFBMeetingBenefit = (
  fbProfit: number,
  netRooms: number
) => {
  return fbProfit * netRooms;
};

export const calculatePlannersAdvCost = (
  points: number,
  rate_requested: number,
  rooms_requested: number
) => {
  return points * rate_requested * rooms_requested * 0.0055 * -1;
};

export const calculateVariableOpEx = (
  netRooms: number,
  directVarOpex: number
) => {
  return netRooms * directVarOpex * -1;
};

export const calculateCostofGuestsWalked = (
  guestsWalked: number,
  rate: number
) => {
  return guestsWalked * rate * -1;
};

export const calculateCompRoomCost = (compRooms: number, rate: number) => {
  return compRooms * rate * -1;
};

export const calculateRebateCost = (
  rebate: number,
  rooms_requested: number
) => {
  return rebate * rooms_requested * -1;
};

export const calculateNetBenefit = (
  incrementalRoomRev: number,
  costOfGuestsWalked: number,
  compRoomCost: number,
  rebateCost: number,
  displacementCost: number,
  incrementalVariableCost: number,
  incrementalCommission: number,
  fbBenefit: number,
  plannersAdvCost: number
) => {
  return (
    incrementalRoomRev +
    costOfGuestsWalked +
    compRoomCost +
    rebateCost +
    displacementCost +
    incrementalVariableCost +
    incrementalCommission +
    fbBenefit +
    plannersAdvCost
  );
};

export const calculateTakeGroup = (
  data: NetBenefitData[],
  formValues: GroupPriceToolInput
) => {
  const results = {
    netBenefit: 0,
    requiredProfit: 0,
  };

  const {
    rooms_requested,
    comp_rooms,
    rate_requested,
    rebate,
    planners_adv_points,
    direct_var_opex,
    occ_fcst_override,
    adr_fcst_override,
    wash_factor,
    commission,
    profit_margin,
    f_and_b_profit,
  } = formValues;

  data.forEach((date: NetBenefitData) => {
    const {
      total_capacity,
      current_otb,
      group_booked,
      total_fcst,
      current_rate,
      fcst_rev,
    } = date;

    const fcstADR = calculateForecastADR(
      fcst_rev,
      total_fcst,
      adr_fcst_override
    );
    const totalFcst =
      occ_fcst_override !== 0
        ? calculateOverride(total_fcst, occ_fcst_override) + group_booked
        : total_fcst + group_booked;

    const roomsDisplaced = calculateRoomsDisplaced(
      total_capacity,
      rooms_requested,
      totalFcst
    );
    const guestsWalked = calculateGuestsWalked(
      total_capacity,
      rooms_requested,
      current_otb
    );
    const costOfGuestsWalked = calculateCostofGuestsWalked(
      guestsWalked,
      current_rate
    );
    const netRoomsAdded = calculateNetRoomsAdded(
      total_capacity,
      rooms_requested,
      totalFcst,
      wash_factor
    );
    const incrementalRoomRevenue = calculateIncrementalRoomRevenue(
      netRoomsAdded,
      rate_requested
    );
    const displacementCost = calculateDisplacementCost(
      roomsDisplaced,
      current_rate,
      rate_requested
    );
    const compRoomCost = calculateCompRoomCost(comp_rooms, rate_requested);
    const rebateCost = calculateRebateCost(rebate, rooms_requested);
    const incrementalCommission = calculateIncrementalCommission(
      roomsDisplaced,
      fcstADR,
      commission,
      rate_requested
    );
    const fbMeetingBenefit = calculateFBMeetingBenefit(
      f_and_b_profit,
      netRoomsAdded
    );
    const plannersAdvCost = calculatePlannersAdvCost(
      planners_adv_points,
      rate_requested,
      rooms_requested
    );

    const requiredProfit = calculateRequiredProfit(
      rooms_requested,
      rate_requested,
      profit_margin
    );

    const variableOpEx = calculateVariableOpEx(netRoomsAdded, direct_var_opex);

    const netBenefit = calculateNetBenefit(
      incrementalRoomRevenue,
      costOfGuestsWalked,
      compRoomCost,
      rebateCost,
      displacementCost,
      variableOpEx,
      incrementalCommission,
      fbMeetingBenefit,
      plannersAdvCost
    );

    results.netBenefit += netBenefit;
    results.requiredProfit += requiredProfit;
  });

  return results.netBenefit > results.requiredProfit;
};

export const calculateShoulderRoomsFromStayover = (
  transientFcst: number,
  checkInDate: string,
  checkOutDate: string,
  stayDate: string,
  roomsDisplaced: number,
  priorStayOvers: number,
  postStayOvers: number
) => {
  const checkIn = dataDate(checkInDate);
  const checkOut = dataDate(checkOutDate);
  const stay = dataDate(stayDate);

  const priorStayOverRatio = priorStayOvers / transientFcst;
  const postStayOverRatio = postStayOvers / transientFcst;

  //If staydate is not checkin or day before checkout, or roomsDisplaced is 0, return 0
  if (stay !== checkIn || stay !== checkOut || roomsDisplaced === 0) {
    return 0;
  }

  //If staydate is checkin and day before checkout
  if (
    stay === checkIn &&
    addDays(1, stay).format(dateFormatData) === checkOut
  ) {
    return (
      Math.round(roomsDisplaced * priorStayOverRatio) +
      Math.round(roomsDisplaced * postStayOverRatio)
    );
  }

  //If staydate is checkin
  if (stay === checkIn) {
    return Math.round(roomsDisplaced * priorStayOverRatio);
  }

  //If staydate is day before checkout
  if (addDays(1, stay).format(dateFormatData) === checkOut) {
    return Math.round(roomsDisplaced * postStayOverRatio);
  }

  return 0;
};

export const calculateShoulderRoomsDisplaced = (
  roomsDisplaced: number,
  avgStayLength: number,
  checkInDate: string,
  lastDate: string,
  stayDate: string
) => {
  const checkIn = dataDate(checkInDate);
  const last = dataDate(lastDate);
  const stay = dataDate(stayDate);
  //If staydate is not check in or day before checkout, or roomsDisplaced is 0, return 0
  if ((stay !== checkIn && stay) !== last || roomsDisplaced === 0) {
    return 0;
  }
  //If staydate is check in and day before checkout
  if (stay === checkIn && addDays(1, stay).format(dateFormatData) === last) {
    return Math.round(roomsDisplaced * avgStayLength * 2);
  }
  //If staydate is check in or day before checkout
  if (stay === checkIn || stay === last) {
    return Math.round(roomsDisplaced * avgStayLength);
  }
  return 0;
};

export const calculateShoulderDisplacementCost = (
  roomsDisplaced: number,
  transientRate: number,
  rate_requested: number
) => {
  return roomsDisplaced * (transientRate - rate_requested) * -1;
};
