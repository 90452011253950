import './Events.scss';
import MuiAlert, { AlertProps, AlertColor } from '@mui/material/Alert';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { EventList } from './EventList';
import EventsForm from '../Events/EventsForm';
import { Snackbar } from '@mui/material';
import { TwoPaneLayout } from 'components/two-pane-layout/two-pane-layout';
import { labels } from 'locales/en.label';
import { useUser } from 'features/users/context/userContext';
import {
  useCreateEvent,
  useDeleteEventRecord,
  useDeleteEvents,
  useUpdateEvent,
} from 'features/events/hooks/event-hooks';
import { EventCategoryEnum, EventFormData } from 'graphql/gql-types';
import {
  initialEventFormState,
  selectEventsFormData,
  selectSelectedEvents,
  setEventsFormRef,
  setIsEventNameReadonly,
  setIsEventsFormDirty,
  setSelectedEvents,
  updateEventsFormValues,
} from 'features/events/redux/events-form-slice';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { today } from 'features/dates/date-helpers';
import { usePropertyContext } from 'context/propertyContext';
import { Modal } from 'components/Modal/Modal';
import { alertAdded } from 'features/alerts/redux/alerts-slice';
import { useModal } from 'components/Modal/useModal';
import { FormikProps } from 'formik';
import { AlertMessage } from 'types/EventsTypes';

export type MessageType = {
  message: string;
  messageType: AlertColor;
};

export interface EventValues {
  name: string;
  recurs: boolean;
  category: EventCategoryEnum;
  occ_impact: number;
  property_id: string;
  created_by_id: string;
  created_at: string;
  updated_by_id: string;
  updated_at: string;
}
export interface EventFormValues {
  id: string | null;
  event_id: string;
  start_date: string;
  end_date: string;
  event: EventValues;
}

const Alert = React.forwardRef<HTMLDivElement, AlertProps>((props, ref) => {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});

export const Events = () => {
  const { createEvent, createEventLoading } = useCreateEvent();
  const { updateEvent, updateEventLoading } = useUpdateEvent();
  const [loading, setLoading] = useState(true);
  const [alertMessage, setAlertMessage] = useState<MessageType | null>(null);
  const [eventFormData, setEventFormData] = useState(
    initialEventFormState.formValues
  );
  const { user } = useUser();

  const {
    property: { propertyId },
  } = usePropertyContext();

  useEffect(() => {
    setTimeout(() => setLoading(false), 1000);
  });

  const dispatch = useAppDispatch();
  const { deleteEvents, deleteEventsLoading } = useDeleteEvents();
  const { deleteEventRecord, deleteEventRecordLoading } =
    useDeleteEventRecord();
  const [isAlertMessageVisible, setIsAlertMessageVisible] = useState(false);
  const { isShown, toggle } = useModal();
  const eventsFormRef = useRef<FormikProps<EventFormData>>(null);
  const selectedEvents = useAppSelector(selectSelectedEvents);
  useEffect(() => {
    dispatch(setEventsFormRef(eventsFormRef.current));
    dispatch(setIsEventsFormDirty(eventsFormRef.current?.dirty || false));
  }, [eventsFormRef, dispatch]);

  const onError = useCallback(
    ({ message, messageType }: AlertMessage) => {
      dispatch(alertAdded(messageType, message));
    },
    [dispatch]
  );

  const onAlertMessageClose = () => {
    setIsAlertMessageVisible(false);
  };

  /* const onEventCopy = useCallback(
    (eventObj: EventResponse) => {
      if (isEventNameReadonly) dispatch(setIsEventNameReadonly(false));
      const copyEvent: any = {
        ...eventObj,
        event_id: '',
        name: labels.rules.copy + eventObj.event?.name,
      };

      dispatch(updateEventsFormValues({ ...copyEvent }));
      eventsFormRef.current?.resetForm({ values: copyEvent });
      setEventsFormRef({ values: copyEvent });
    },
    [isEventNameReadonly, dispatch]
  ); */

  const onDelete = () => {
    const selectedEvent: any = eventsFormRef?.current?.values;
    deleteEvents(selectedEvent, () => {
      toggle();
      const alertMessage = `${selectedEvent.name} ${labels.events.delete_single_success_msg}`;
      dispatch(alertAdded('success', alertMessage));
      eventsFormRef.current?.resetForm({
        values: initialEventFormState.formValues,
      });
      dispatch(setSelectedEvents([]));
    });
  };

  const onRecordDelete = (record: any) => {
    if (record.start_date && record.end_date) {
      deleteEventRecord(record, () => {
        const alertMessage = `${labels.events.delete_event_record_success_msg}`;
        dispatch(alertAdded('success', alertMessage));
      });
    }
  };

  const onFormReset = useCallback(async () => {
    dispatch(setIsEventNameReadonly(false));
    eventsFormRef.current?.resetForm({
      values: initialEventFormState.formValues,
    });
    dispatch(updateEventsFormValues({ ...initialEventFormState.formValues }));
  }, [dispatch]);

  const onSaveClick = async () => {
    const values: any = eventsFormRef?.current?.values;
    if (values?.event_id) {
      const updatedEvent = await updateEvent(
        {
          id: values.id,
          event_id: values.event_id,
          name: values.name,
          category: values.category,
          records: values.records,
          occ_impact: values.occ_impact,
          recurs: values.records.length > 1 ? true : false,
          created_by_id: user?.id,
          created_at: today(),
          updated_by_id: user?.id,
          updated_at: today(),
          property_id: propertyId,
        },
        propertyId
      );
      if (updatedEvent?.success) {
        setAlertMessage({
          messageType: 'success',
          message: labels.events.create_confirmation_msg,
        });
      }
    } else {
      const newSeason = await createEvent({
        name: values.name,
        category: values.category,
        records: values.records,
        occ_impact: values.occ_impact,
        recurs: values.records.length > 1 ? true : false,
        created_by_id: user?.id,
        created_at: today(),
        updated_by_id: user?.id,
        updated_at: today(),
        property_id: propertyId,
      });
      if (newSeason?.success) {
        setAlertMessage({
          messageType: 'success',
          message: labels.events.modify_confirmation_msg,
        });
      }
    }
  };

  const formData = useAppSelector(selectEventsFormData);

  useEffect(() => {
    if (formData.event_id !== '') {
      setEventFormData(formData);
    } else {
      setEventFormData(initialEventFormState.formValues);
    }
  }, [formData]);

  return (
    <div className='events-container'>
      <div className='error'>
        <Snackbar
          className='error-msg'
          open={isAlertMessageVisible}
          autoHideDuration={3000}
          onClose={onAlertMessageClose}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <Alert
            onClose={onAlertMessageClose}
            severity={alertMessage?.messageType}
            sx={{ width: '100%' }}
          >
            {alertMessage?.message}
          </Alert>
        </Snackbar>
      </div>
      <Modal
        isShown={isShown}
        hide={toggle}
        onConfirm={onDelete}
        onCancel={toggle}
        loading={deleteEventsLoading}
        isConfirmationModal
        hasIcon
        headerText={labels.events.modal_header_text}
        confirmBtnText={labels.common.confirm}
        cancelBtnText={labels.common.cancel}
        messageBody={`${labels.events.confirmation_message}\n${selectedEvents
          .map((e) => e?.event?.name)
          .join('\n')}`}
      />

      <TwoPaneLayout
        loading={
          loading ||
          updateEventLoading ||
          createEventLoading ||
          deleteEventRecordLoading
        }
        LeftSideComponent={
          <EventsForm
            formRef={eventsFormRef}
            id='events-form'
            onFormReset={onFormReset}
            readonly={false}
            formData={eventFormData}
            onSaveClick={onSaveClick}
            onDeleteClick={toggle}
            onRecordDelete={onRecordDelete}
          />
        }
        RightSideComponent={
          <EventList
            onError={onError}
            //onEventCopy={onEventCopy}
            onFormReset={onFormReset}
          />
        }
      />
    </div>
  );
};
