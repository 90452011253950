import { useEffect, useState } from 'react';
import { FixMeLater } from 'types/FixMeLaterType';
import { GET_SELLOUT_INDICATOR } from 'queries/gqlRecReview';
import { MdOutlineWarning } from 'react-icons/md';
import { Modal } from 'components/Modal/Modal';
import { useApolloClient } from '@apollo/client';
import { usePropertyContext } from 'context/propertyContext';

export const AlertPopup = (props: FixMeLater) => {
  const [showModal, setShowModal] = useState(false);
  const [message, setMessage] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const { property } = usePropertyContext();
  const propertyId = property?.propertyId;
  const stayDate = props.data.stay_date;
  const client = useApolloClient();

  useEffect(() => {
    setLoading(true);
    setShowModal(true);
    client
      .query({
        query: GET_SELLOUT_INDICATOR,
        variables: { propertyId, stayDate },
      })
      .then(({ data }: any) => {
        if (data) {
          const newMessages: string[] = data.getSelloutReasons;
          if (newMessages.length > 0) {
            setMessage(newMessages);
          } else {
            setMessage(['No Sellout Messages']);
          }

          setLoading(false);
        }
      });
  }, [client, propertyId, stayDate]);
  const toggle = () => {
    setShowModal(false);
    props.closeModal();
  };

  const listitems = message?.map((msg, idx) => <li key={idx}>{msg}</li>);

  return (
    <Modal
      isShown={showModal}
      loading={loading}
      hide={toggle}
      headerText={'Potential Sellout !!'}
      messageBody={<ul>{listitems}</ul>}
      hasIcon={true}
      icon={<MdOutlineWarning size={35} color='#FD6F6F' />}
    />
  );
};
