import { useAppDispatch, useAppSelector } from 'redux/hooks';

import { Link } from 'react-router-dom';
import { selectRecReviewTab } from 'features/my-view/redux/selectors';
import { setRecReviewTab } from 'features/my-view/redux/my-view-slice';
import { tabItems } from '../config';
import { useDroppable } from '@dnd-kit/core';

export const Tabs = () => {
  const dispatch = useAppDispatch();
  const activetab = useAppSelector(selectRecReviewTab);
  const onTabClick = (key: string) => {
    dispatch(setRecReviewTab(key));
  };
  const {
    isOver,
    setNodeRef,
    active: dragActive,
  } = useDroppable({
    id: 'metrics-tab',
  });

  return (
    <div className='dashboard__tabs'>
      {tabItems.map((tab, idx) => {
        const droppable =
          dragActive && dragActive.data.current?.action === 'add';

        const classes: string[] = [
          `dashboard__tab dashboard__tab-${tab.class}`,
        ];
        if (activetab && activetab === tab.key)
          classes.push('dashboard__tab-activeTab');
        if (tab.key === 'metrics' && droppable) {
          classes.push('dashboard__tab-droppable');
          if (isOver) classes.push('dashboard__tab-drop-target');
        }

        return (
          <div
            className={classes.join(' ')}
            key={`tabItem-${idx}`}
            ref={tab.key === 'metrics' ? setNodeRef : null}
          >
            <Link
              to={`${tab.to}`}
              onClick={() => {
                onTabClick(tab.key);
              }}
            >
              {tab.title}
            </Link>
          </div>
        );
      })}
    </div>
  );
};
