import { Redirect, Route, Switch } from 'react-router-dom';

import { Admin } from 'pages/Admin/Admin';
import ArticleEditor from 'pages/Help/components/article-editor/ArticleEditor';
import ArticleForm from 'pages/Help/components/form/ArticleForm';
import Changelog from 'Changelog';
import { Events } from 'pages/Events/Events';
import { GroupPriceTool } from 'pages/groups/group-price-tool';
import { Help } from 'pages/Help/help';
import { Home } from 'pages/home/home';
import { LoginCallback } from '@okta/okta-react';
import { MyView } from 'pages/MyView/MyView';
import { PrivateRoute } from 'components/PrivateRoute/PrivateRoute';
import { Main as RateChangeLog } from 'pages/rate-change-log/main';
import { Main as RateOffsetLog } from 'pages/rate-offset-log/main';
import { RecReviewCharts } from 'pages/MyView/RecReviewCharts/RecReviewCharts';
import { Rules } from 'pages/rules/rules';
import { Settings } from 'pages/Settings/settings';
import { VideoUploader } from 'pages/Admin/components/video-uploader/video-uploader';
import { useAppSelector } from 'redux/hooks';
import useDeviceType from 'hooks/useDeviceType';
import { usePropertyContext } from 'context/propertyContext';

export const AppRoutes = () => {
  const { property } = usePropertyContext();
  const superAdmin = useAppSelector((state) => state.users.isSuperAdmin);
  const { isMobile } = useDeviceType();

  return (
    <Switch>
      <Route path='/login/callback' component={LoginCallback} exact />
      {!isMobile && property?.propertyId ? (
        <PrivateRoute path='/' exact>
          <Redirect to='/my-view/metrics' />
        </PrivateRoute>
      ) : (
        <Route path='/' exact>
          <Redirect to='/home' />
        </Route>
      )}
      <PrivateRoute path='/home' exact>
        <Home />
      </PrivateRoute>
      <PrivateRoute path='/changelog' exact>
        <Changelog />
      </PrivateRoute>
      <Route path='/rules' exact>
        <Redirect to='/rules/seasons' />
      </Route>
      <PrivateRoute path='/rules/:selectedTab'>
        <Rules />
      </PrivateRoute>
      <PrivateRoute path='/events' exact>
        <Events />
      </PrivateRoute>
      <PrivateRoute path='/my-view/graph' exact>
        <RecReviewCharts />
      </PrivateRoute>
      <PrivateRoute path='/my-view/:tab'>
        <MyView />
      </PrivateRoute>
      <PrivateRoute path='/settings' exact>
        <Settings />
      </PrivateRoute>
      <PrivateRoute path='/rate_changes' exact>
        <RateChangeLog />
      </PrivateRoute>
      <PrivateRoute path='/rate_offsets' exact>
        <RateOffsetLog />
      </PrivateRoute>
      <PrivateRoute path='/groups' exact>
        <GroupPriceTool />
      </PrivateRoute>
      <PrivateRoute path='/help/create' exact>
        {superAdmin ? <ArticleForm /> : <Redirect to='/home' />}
      </PrivateRoute>
      <PrivateRoute path='/help' exact>
        <Redirect to='/help/general' />
      </PrivateRoute>
      <PrivateRoute path='/help/edit/:slug' exact>
        {superAdmin ? <ArticleEditor /> : <Redirect to='/home' />}
      </PrivateRoute>
      <PrivateRoute path='/help/:category/:slug?' exact>
        <Help />
      </PrivateRoute>

      <PrivateRoute path='/admin' exact>
        {superAdmin ? <Admin /> : <Redirect to='/home' />}
      </PrivateRoute>
      <PrivateRoute path='/admin/video-uploader' exact>
        {superAdmin ? <VideoUploader /> : <Redirect to='/home' />}
      </PrivateRoute>
    </Switch>
  );
};
