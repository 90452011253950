import { User, UserRole } from 'graphql/gql-types';
import { createContext, useContext, useEffect, useState } from 'react';
import { isEqual, pick } from 'lodash';
import {
  useCreateUserConnectionLogMutation,
  useCreateUserMutation,
  useGetAffiliatePropertiesLazyQuery,
  useGetUserByLoginIdLazyQuery,
  useUpdateUserMutation,
} from '../gql/_gen_/user.gql';

import { AuthState } from '@okta/okta-auth-js';
import { apolloErrorAdded } from 'features/alerts/redux/alerts-slice';
import { maskString } from 'helpers/maskString';
import { setSuperAdmin } from 'features/users/redux/users-slice';
import { timeStampMinute } from 'features/dates/date-helpers';
import { useAppDispatch } from 'redux/hooks';
import { useOktaAuth } from '@okta/okta-react';

export type OktaUserState = OktaUserCustom | undefined;
type Dispatch = (user: OktaUserState) => void;

export enum OktaProfileType {
  CORPORATE = 'CORPORATE',
  HOTEL = 'HOTEL',
}

export enum EmployeeTypeIdm {
  AFFILIATE_STAFF = 'AFFILIATE_STAFF',
  EMPLOYEE = 'EMPLOYEE',
  HOTEL_STAFF = 'HOTEL_STAFF',
}

export enum BestRevGroups {
  FULL_ACCESS = 'OH-Bestrev2.0-Full Access - Admin',
  READ_ONLY = 'OH-Bestrev2.0-ReadOnly - ReadOnly',
}

export const readOnlyMutations = [
  'CreateUser',
  'CreateUserHotelMetrics',
  'DeleteSearch',
  'LogTracking',
  'SaveSearch',
  'UpdateUser',
  'UpdateUserHotelMetrics',
];

// Copies IDToken["claims"] from '@okta/okta-auth-js', but adds
// userRole and mw_groups to the object.
export type OktaUserCustom = {
  affiliateOffice?: string;
  auth_time?: number;
  aud?: string;
  email?: string;
  email_verified?: boolean;
  employeeTypeIDM?: EmployeeTypeIdm;
  exp?: number;
  family_name?: string;
  firstName?: string;
  given_name?: string;
  iat?: number;
  initials?: string;
  iss?: string;
  jti?: string;
  lastName?: string;
  locale?: string;
  loginId?: string;
  name?: string;
  nonce?: string;
  preferred_username?: string;
  profileType?: OktaProfileType;
  propId?: string[];
  sub?: string;
  updated_at?: number;
  ver?: number;
  zoneinfo?: string;
  at_hash?: string;
  userRole?: string;
  mw_groups?: string[];
  Bestrev_groups?: BestRevGroups[];
};

type UserContextProps = {
  isAuthenticated: boolean;
  oktaUser: OktaUserState;
  setOktaUser: Dispatch;
  user?: User;
  isReadOnly: boolean;
  locale: string;
};
type UserProviderProps = { children: React.ReactNode };

export const UserContext = createContext<UserContextProps | undefined>(
  undefined
);

export const isRSM = (oktaUser: OktaUserState) => {
  const groups = oktaUser?.Bestrev_groups?.map((group) => group.toLowerCase());
  return (
    oktaUser?.profileType === OktaProfileType.CORPORATE &&
    groups?.includes(BestRevGroups.READ_ONLY.toLowerCase()) &&
    !groups?.includes(BestRevGroups.FULL_ACCESS.toLowerCase())
  );
};

export const isAffiliateStaff = (oktaUser: OktaUserState) => {
  if (!oktaUser) return false;
  if (!oktaUser.employeeTypeIDM) return false;
  if (
    !oktaUser.affiliateOffice ||
    oktaUser.affiliateOffice === '' ||
    oktaUser.affiliateOffice === null
  )
    return false;
  return oktaUser.employeeTypeIDM === EmployeeTypeIdm.AFFILIATE_STAFF;
};

const getUserRole = (oktaUser: OktaUserState) => {
  if (!oktaUser) return;

  if (isRSM(oktaUser)) {
    return UserRole.corp_read;
  }

  if (oktaUser?.profileType === OktaProfileType.HOTEL) {
    return UserRole.hotel_full;
  }

  return UserRole.corp_full;
};

function UserProvider({ children }: UserProviderProps) {
  const dispatch = useAppDispatch();
  const { authState } = useOktaAuth();
  const [oktaUser, setOktaUser] = useState<OktaUserState>(undefined);
  const [user, setUser] = useState<User>();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isReadOnly, setIsReadOnly] = useState(false);
  const [locale, setLocale] = useState('en-US');
  const [getUserByLoginId] = useGetUserByLoginIdLazyQuery();
  const [createUser] = useCreateUserMutation();
  const [updateUser] = useUpdateUserMutation();
  const [createUserConLog] = useCreateUserConnectionLogMutation();
  const [getAffiliateProperties] = useGetAffiliatePropertiesLazyQuery();

  const getAffiliateHotels = async (affiliateCode: string) => {
    const { data } = await getAffiliateProperties({
      variables: {
        affiliateCode,
      },
    });

    return data?.getAffiliateProperties?.map((prop) => prop?.property_id) || [];
  };

  const areSame = async (newUser: User, newOktaUser: OktaUserState) => {
    const foundUser = pick(newUser, [
      'id',
      'first_name',
      'last_name',
      'login_id',
      'name',
      'okta_id',
      'prop_id',
    ]);

    const prop_id = isAffiliateStaff(newOktaUser)
      ? await getAffiliateHotels(newOktaUser?.affiliateOffice!)
      : newOktaUser?.propId;

    const updateAttributes = {
      id: foundUser.id!,
      first_name: newOktaUser?.firstName!,
      last_name: newOktaUser?.lastName!,
      login_id: newOktaUser?.loginId!,
      name: newOktaUser?.name!,
      okta_id: newOktaUser?.sub,
      prop_id: prop_id || [],
    };

    return { sameUser: isEqual(foundUser, updateAttributes), updateAttributes };
  };

  function sendConnectionLog(data: AuthState, success: boolean) {
    const maskedData = mapOktaObject(data);
    if (!maskedData?.idToken?.claims?.email) return;

    createUserConLog({
      variables: {
        email: maskedData.idToken.claims.email,
        data: JSON.stringify(maskedData),
        success,
      },
    });
  }

  useEffect(() => {
    if (authState) {
      if (authState.isAuthenticated) sendConnectionLog(authState, true);
      setIsAuthenticated(authState?.isAuthenticated || false);
      setLocale(navigator.language);
      const oktaUser = authState?.idToken?.claims as OktaUserState;
      if (oktaUser) {
        setOktaUser({ ...oktaUser });
        if (isRSM(oktaUser)) {
          setIsReadOnly(true);
        }
        getUserByLoginId({
          variables: {
            loginId: oktaUser.loginId!,
          },
          onCompleted: async (data) => {
            const user = data.getUserByLoginId;
            if (user && user.id !== null) {
              const { sameUser, updateAttributes } = await areSame(
                user,
                oktaUser
              );
              const superAdmin = user.super_admin || false;
              dispatch(setSuperAdmin(superAdmin));
              if (sameUser) {
                setUser(data.getUserByLoginId);
                updateUser({
                  variables: {
                    id: user.id!,
                    last_login: timeStampMinute(),
                  },
                  fetchPolicy: 'no-cache',
                });
              } else {
                updateUser({
                  variables: {
                    ...updateAttributes,
                    last_login: timeStampMinute(),
                  },
                  onCompleted(data) {
                    setUser(data.updateUser);
                  },
                });
              }
              // If user is RSM, set role to corp_read
              if (isRSM(oktaUser)) {
                if (user.role !== UserRole.corp_read) {
                  updateUser({
                    variables: {
                      id: user.id!,
                      role: UserRole.corp_read,
                    },
                    fetchPolicy: 'no-cache',
                  });
                }
              }
            } else {
              const prop_id = isAffiliateStaff(oktaUser)
                ? await getAffiliateHotels(oktaUser?.affiliateOffice!)
                : oktaUser?.propId;
              createUser({
                variables: {
                  email: oktaUser.email!,
                  first_name: oktaUser.firstName!,
                  last_name: oktaUser.lastName!,
                  login_id: oktaUser.loginId,
                  name: oktaUser.name!,
                  okta_id: oktaUser.sub,
                  prop_id: prop_id || [],
                  role: getUserRole(oktaUser),
                },
                onCompleted(data) {
                  setUser(data.createUser);
                },
              });
            }
          },
          onError(error) {
            sendConnectionLog(authState, false);
            dispatch(apolloErrorAdded(error));
          },
        });
      }
    } else {
      setUser(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authState]);

  const value = {
    isAuthenticated,
    oktaUser,
    setOktaUser,
    user,
    isReadOnly,
    locale,
  };

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
}

function mapOktaObject(oktaObject: any): AuthState {
  const { accessToken, idToken, refreshToken } = oktaObject;
  return {
    accessToken: {
      ...accessToken,
      accessToken: maskString(accessToken.accessToken),
      claims: {
        ...accessToken.claims,
        jti: maskString(accessToken.claims.jti),
        iss: maskString(accessToken.claims.iss, true),
        cid: maskString(accessToken.claims.cid),
        uid: maskString(accessToken.claims.uid),
      },
      authorizeUrl: maskString(accessToken.authorizeUrl, true),
      userinfoUrl: maskString(accessToken.userinfoUrl, true),
    },
    idToken: {
      ...idToken,
      idToken: maskString(idToken.idToken),
      claims: {
        ...idToken.claims,
        sub: maskString(idToken.claims.sub),
        iss: maskString(idToken.claims.iss, true),
        aud: maskString(idToken.claims.aud),
        jti: maskString(idToken.claims.jti),
        idp: maskString(idToken.claims.idp),
        nonce: maskString(idToken.claims.nonce),
        at_hash: maskString(idToken.claims.at_hash),
      },
      authorizeUrl: maskString(idToken.authorizeUrl, true),
      issuer: maskString(idToken.issuer, true),
      clientId: maskString(idToken.clientId),
    },
    refreshToken: {
      ...refreshToken,
      refreshToken: maskString(refreshToken.refreshToken),
      tokenUrl: maskString(refreshToken.tokenUrl, true),
      authorizeUrl: maskString(refreshToken.authorizeUrl, true),
      issuer: maskString(refreshToken.issuer, true),
    },
  };
}

function useUser() {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error(`useUser must be used within a UserProvider`);
  }
  return context;
}

export { UserProvider, useUser };
