import { ColumnObj, SourceColumn } from './useDashboardColumns';
import {
  ICellRendererParams,
  IRowNode,
  ValueFormatterParams,
} from 'ag-grid-community';
import {
  calcTotalForecast,
  oneDecimalFormatter,
  rateLevelFcstValueGetter,
  renderLiveData,
  thousandSeperatorRateFormatter,
  twoDecimalRateFormatter,
  zeroDecimalFormatter,
} from './tableCustomCellRender';

import { LiveDataField } from 'components/live-data-field';
import { RateCellRender } from './RateCellRender';
import { RecReviewData } from './useDashboardController';
import { labels } from 'locales/en.label';

export enum ColumnSources {
  FORECAST = 'Forecast',
  INVENTORY = 'Inventory',
  KPI = 'KPI',
  PICKUP = 'Pickup',
  RATES = 'Rates',
  COMPETITORS = 'Competitors',
}

const liveDataComparator: (
  fieldName: keyof RecReviewData
) => ColumnObj['comparator'] =
  (fieldName) => (liveValA, liveValB, nodeA, nodeB, isDescending) => {
    const rateA = liveValA ?? nodeA.data?.[fieldName];
    const rateB = liveValB ?? nodeB.data?.[fieldName];

    if (rateA === null) {
      return isDescending ? -1 : 1;
    }
    return rateA - rateB;
  };

export const metricsDefaultCols: ColumnObj[] = [
  {
    headerName: 'Available\nRooms',
    field: 'available_rooms_live',
    headerTooltip: labels.rec_review.dashboard.tooltip.available_rooms,
    maxWidth: 100,
    valueFormatter: (params: ValueFormatterParams) => {
      return zeroDecimalFormatter(params);
    },
    cellRenderer: (params: ICellRendererParams) => {
      return renderLiveData(
        { ...params },
        'available_rooms_live',
        'available_rooms',
        false,
        zeroDecimalFormatter
      );
    },
  },
  {
    headerName: 'Transient\nCapacity',
    field: 'transient_capacity_live',
    headerTooltip: labels.rec_review.dashboard.tooltip.transient_cap,
    maxWidth: 100,
    valueFormatter: (params: ValueFormatterParams) => {
      return zeroDecimalFormatter(params?.value);
    },
    cellRenderer: (params: ICellRendererParams) => {
      return renderLiveData(
        { ...params },
        'transient_capacity_live',
        'transient_cap',
        false,
        zeroDecimalFormatter
      );
    },
  },
  {
    headerName: 'Total Fcst\n(incl Grp)',
    field: 'total_fcst_incl_group',
    headerTooltip: labels.rec_review.dashboard.tooltip.total_fcst,
    maxWidth: 100,
    valueFormatter: (params: ValueFormatterParams) => {
      if (!params.data) {
        return zeroDecimalFormatter(params?.value);
      }

      const { total_fcst_incl_group, total_fcst_incl_group_live } =
        calcTotalForecast(params.data) || {};

      if (total_fcst_incl_group_live) {
        return zeroDecimalFormatter(total_fcst_incl_group_live);
      } else if (total_fcst_incl_group) {
        return zeroDecimalFormatter(total_fcst_incl_group);
      } else {
        return zeroDecimalFormatter(params?.value);
      }
    },
    cellRenderer: (params: ICellRendererParams) => {
      const forecasts = calcTotalForecast(params.data);

      return renderLiveData(
        { ...params, data: forecasts },
        'total_fcst_incl_group_live',
        'total_fcst_incl_group',
        false,
        zeroDecimalFormatter
      );
    },
    comparator: (valueA: undefined, valueB: undefined, nodeA, nodeB) => {
      const getForecastValue = (node: IRowNode<RecReviewData>) => {
        const { total_fcst_incl_group, total_fcst_incl_group_live } =
          calcTotalForecast(node.data) || {};
        return total_fcst_incl_group_live ?? total_fcst_incl_group ?? 0;
      };
      const forecastA = getForecastValue(nodeA);
      const forecastB = getForecastValue(nodeB);

      return forecastA - forecastB;
    },
  },
  {
    headerName: 'OTB\n(incl Grp)',
    field: 'current_otb_live',
    headerTooltip: labels.rec_review.dashboard.tooltip.otb,
    maxWidth: 100,
    valueFormatter: (params: ValueFormatterParams) => {
      return zeroDecimalFormatter(params?.value);
    },
    cellRenderer: (params: ICellRendererParams) => {
      return renderLiveData(
        { ...params },
        'current_otb_live',
        'current_otb',
        false,
        zeroDecimalFormatter
      );
    },
    comparator: liveDataComparator('current_otb'),
  },
  {
    headerName: 'ADR',
    field: 'adr_live',
    headerTooltip: labels.rec_review.dashboard.tooltip.adr,
    maxWidth: 100,
    valueFormatter: (params: ValueFormatterParams) => {
      return twoDecimalRateFormatter(params?.value);
    },
    cellRenderer: (params: ICellRendererParams) => {
      return renderLiveData(
        { ...params },
        'adr_live',
        'adr',
        true,
        twoDecimalRateFormatter
      );
    },
    comparator: liveDataComparator('adr'),
  },
  {
    headerName: 'RevPAR',
    field: 'revpar_live',
    headerTooltip: labels.rec_review.dashboard.tooltip.revpar,
    maxWidth: 100,
    cellRenderer: (params: ICellRendererParams) => {
      return renderLiveData(
        { ...params },
        'revpar_live',
        'revpar',
        true,
        twoDecimalRateFormatter
      );
    },
    valueFormatter: (params: ValueFormatterParams) => {
      return twoDecimalRateFormatter(params?.value);
    },
    comparator: liveDataComparator('revpar'),
  },
  {
    headerName: 'Curr Occ %\n(incl Grp)',
    field: 'occ_pct_live',
    headerTooltip: labels.rec_review.dashboard.tooltip.occ_pct,
    maxWidth: 100,
    valueFormatter: (params: ValueFormatterParams) => {
      return oneDecimalFormatter(params?.value);
    },
    cellRenderer: (params: ICellRendererParams) => {
      return renderLiveData(
        { ...params },
        'occ_pct_live',
        'occ_pct',
        false,
        oneDecimalFormatter
      );
    },
    comparator: liveDataComparator('occ_pct'),
  },
  {
    headerName: 'Fcst Occ %\n(incl Grp)',
    field: 'fcst_occ_pct',
    headerTooltip: labels.rec_review.dashboard.tooltip.fcst_occ_pct,
    maxWidth: 120,
    valueFormatter: (params: ValueFormatterParams) => {
      if (!params.data) {
        return oneDecimalFormatter(params?.value);
      }

      const { fcst_occ_pct, fcst_occ_pct_live } =
        calcTotalForecast(params.data) || {};

      if (fcst_occ_pct_live) {
        return oneDecimalFormatter(fcst_occ_pct_live);
      } else if (fcst_occ_pct) {
        return oneDecimalFormatter(fcst_occ_pct);
      } else {
        return oneDecimalFormatter(params?.value);
      }
    },
    cellRenderer: (params: ICellRendererParams) => {
      const forecasts = calcTotalForecast(params.data);

      return renderLiveData(
        { ...params, data: forecasts },
        'fcst_occ_pct_live',
        'fcst_occ_pct',
        false,
        oneDecimalFormatter
      );
    },
    comparator: (valueA: undefined, valueB: undefined, nodeA, nodeB) => {
      const getForecastValue = (node: IRowNode<RecReviewData>) => {
        const { fcst_occ_pct, fcst_occ_pct_live } =
          calcTotalForecast(node.data) || {};
        return fcst_occ_pct_live ?? fcst_occ_pct ?? 0;
      };
      const forecastA = getForecastValue(nodeA);
      const forecastB = getForecastValue(nodeB);

      return forecastA - forecastB;
    },
  },
];
export const forecastColsData: SourceColumn[] = [
  {
    headerName: 'Transient\nFcst',
    field: 'transient_fcst',
    headerTooltip: labels.rec_review.dashboard.tooltip.transient_fcst,
    maxWidth: 100,
    valueFormatter: (params: ValueFormatterParams) => {
      return zeroDecimalFormatter(params?.value);
    },
    source: ColumnSources.FORECAST,
  },
  {
    headerName: 'Unc Transient Fcst',
    field: 'unc_transient_fcst',
    headerTooltip: labels.rec_review.dashboard.tooltip.unc_transient_fcst,
    source: ColumnSources.FORECAST,
  },
  // Hidden from display until fully implemented per B20V-2035:
  // {
  //   headerName: 'Unc Dmd @\nBWH',
  //   field: 'unc_dmd_curr_rate',
  //   headerTooltip: labels.rec_review.dashboard.tooltip.unc_dmd_curr_rate,
  //   valueFormatter: (params: ValueFormatterParams) => {
  //     return twoDecimalRateFormatter(params?.value);
  //   },
  //   source: ColumnSources.FORECAST,
  // },
  // {
  //   headerName: 'Con Dmd @\nBWH',
  //   field: 'con_dmd_curr_rate',
  //   headerTooltip: labels.rec_review.dashboard.tooltip.con_dmd_curr_rate,
  //   valueFormatter: (params: ValueFormatterParams) => {
  //     return twoDecimalRateFormatter(params?.value);
  //   },
  //   source: ColumnSources.FORECAST,
  // },
  // {
  //   headerName: 'Con Dmd @\nRec',
  //   field: 'con_dmd_rec_rate',
  //   headerTooltip: labels.rec_review.dashboard.tooltip.con_dmd_rec_rate,
  //   valueFormatter: (params: ValueFormatterParams) => {
  //     return twoDecimalRateFormatter(params?.value);
  //   },
  //   source: ColumnSources.FORECAST,
  // },
  {
    headerName: 'Level A\nOTB / Fcst / Status',
    field: 'a_fcst_sell',
    headerTooltip: labels.rec_review.dashboard.tooltip.fcst_lvl_rate,
    cellRenderer: (params: ICellRendererParams) =>
      rateLevelFcstValueGetter(params, 'a'),
    source: ColumnSources.FORECAST,
  },
  {
    headerName: 'Level B\nOTB / Fcst / Status',
    field: 'b_fcst_sell',
    headerTooltip: labels.rec_review.dashboard.tooltip.fcst_lvl_rate,
    cellRenderer: (params: ICellRendererParams) =>
      rateLevelFcstValueGetter(params, 'b'),
    source: ColumnSources.FORECAST,
  },
  {
    headerName: 'Level C\nOTB / Fcst / Status',
    field: 'c_fcst_sell',
    headerTooltip: labels.rec_review.dashboard.tooltip.fcst_lvl_rate,
    cellRenderer: (params: ICellRendererParams) =>
      rateLevelFcstValueGetter(params, 'c'),
    source: ColumnSources.FORECAST,
  },
  {
    headerName: 'Level D\nOTB / Fcst / Status',
    field: 'd_fcst_sell',
    headerTooltip: labels.rec_review.dashboard.tooltip.fcst_lvl_rate,
    cellRenderer: (params: ICellRendererParams) =>
      rateLevelFcstValueGetter(params, 'd'),
    source: ColumnSources.FORECAST,
  },
  {
    headerName: 'Level E\nOTB / Fcst / Status',
    field: 'e_fcst_sell',
    headerTooltip: labels.rec_review.dashboard.tooltip.fcst_lvl_rate,
    cellRenderer: (params: ICellRendererParams) =>
      rateLevelFcstValueGetter(params, 'e'),
    source: ColumnSources.FORECAST,
  },
  {
    headerName: 'Level F\nOTB / Fcst / Status',
    field: 'f_fcst_sell',
    headerTooltip: labels.rec_review.dashboard.tooltip.fcst_lvl_rate,
    cellRenderer: (params: ICellRendererParams) =>
      rateLevelFcstValueGetter(params, 'f'),
    source: ColumnSources.FORECAST,
  },
  {
    headerName: 'Level G\nOTB / Fcst / Status',
    field: 'g_fcst_sell',
    headerTooltip: labels.rec_review.dashboard.tooltip.fcst_lvl_rate,
    cellRenderer: (params: ICellRendererParams) =>
      rateLevelFcstValueGetter(params, 'g'),
    source: ColumnSources.FORECAST,
  },
  {
    headerName: 'Level H\nOTB / Fcst / Status',
    field: 'h_fcst_sell',
    headerTooltip: labels.rec_review.dashboard.tooltip.fcst_lvl_rate,
    cellRenderer: (params: ICellRendererParams) =>
      rateLevelFcstValueGetter(params, 'h'),
    source: ColumnSources.FORECAST,
  },
];
export const inventoryColsData: SourceColumn[] = [
  {
    headerName: 'OTB\n(LYST)',
    field: 'current_otb_lyst',
    headerTooltip: labels.rec_review.dashboard.tooltip.otb_lyst,
    valueFormatter: (params: ValueFormatterParams) => {
      return zeroDecimalFormatter(params?.value);
    },
    source: ColumnSources.INVENTORY,
  },
  {
    headerName: 'OTB\n(LYF)',
    field: 'current_otb_lyf',
    headerTooltip: labels.rec_review.dashboard.tooltip.otb_lyf,
    valueFormatter: (params: ValueFormatterParams) => {
      return zeroDecimalFormatter(params?.value);
    },
    source: ColumnSources.INVENTORY,
  },
  {
    headerName: 'Group\nBlocked',
    field: 'group_blocked_live',
    headerTooltip: labels.rec_review.dashboard.tooltip.group_blocked,
    valueFormatter: (params: ValueFormatterParams) => {
      return zeroDecimalFormatter(params?.value);
    },
    cellRenderer: (params: ICellRendererParams) => {
      return renderLiveData(
        { ...params },
        'group_blocked_live',
        'group_blocked',
        false,
        zeroDecimalFormatter
      );
    },
    comparator: liveDataComparator('group_blocked'),
    source: ColumnSources.INVENTORY,
  },
  {
    headerName: 'Group\nBooked',
    field: 'group_booked_live',
    headerTooltip: labels.rec_review.dashboard.tooltip.group_booked,
    valueFormatter: (params: ValueFormatterParams) => {
      return zeroDecimalFormatter(params?.value);
    },
    cellRenderer: (params: ICellRendererParams) => {
      return renderLiveData(
        { ...params },
        'group_booked_live',
        'group_booked',
        false,
        zeroDecimalFormatter
      );
    },
    comparator: liveDataComparator('group_booked'),
    source: ColumnSources.INVENTORY,
  },
  {
    headerName: 'OOO',
    field: 'ooo_live',
    headerTooltip: labels.rec_review.dashboard.tooltip.ooo,
    valueFormatter: (params: ValueFormatterParams) => {
      return zeroDecimalFormatter(params?.value);
    },
    cellRenderer: (params: ICellRendererParams) => {
      return renderLiveData(
        { ...params },
        'ooo_live',
        'out_of_order',
        false,
        zeroDecimalFormatter
      );
    },
    comparator: liveDataComparator('out_of_order'),
    source: ColumnSources.INVENTORY,
  },
  {
    headerName: 'Physical\nCapacity',
    field: 'total_capacity',
    headerTooltip: labels.rec_review.dashboard.tooltip.physical_capacity,
    valueFormatter: (params: ValueFormatterParams) => {
      return zeroDecimalFormatter(params?.value);
    },
    source: ColumnSources.INVENTORY,
  },
  {
    headerName: 'Transient\nCapacity',
    field: 'transient_capacity_live',
    headerTooltip: labels.rec_review.dashboard.tooltip.transient_cap,
    valueFormatter: (params: ValueFormatterParams) => {
      return zeroDecimalFormatter(params?.value);
    },
    cellRenderer: (params: ICellRendererParams) => {
      return renderLiveData(
        { ...params },
        'transient_capacity_live',
        'transient_cap',
        false,
        zeroDecimalFormatter
      );
    },
    comparator: liveDataComparator('transient_cap'),
    source: ColumnSources.INVENTORY,
  },
  {
    headerName: 'Overbooking',
    field: 'overbooking',
    headerTooltip: labels.rec_review.dashboard.overbooking,
    valueFormatter: (params: ValueFormatterParams) => {
      return zeroDecimalFormatter(params?.value);
    },
    source: ColumnSources.INVENTORY,
  },
];
export const kpiColsData: SourceColumn[] = [
  {
    headerName: 'ADR\n(LYST)',
    field: 'adr_lyst',
    headerTooltip: labels.rec_review.dashboard.tooltip.adr_lyst,
    /*  valueFormatter: (params: ValueFormatterParams) => {
      return twoDecimalRateFormatter(params?.value);
    }, */
    cellRenderer: (params: ICellRendererParams) => {
      return (
        <RateCellRender
          params={params}
          digitFormatter={twoDecimalRateFormatter}
        />
      );
    },
    source: ColumnSources.KPI,
  },
  {
    headerName: 'ADR\n(LYF)',
    field: 'adr_lyf',
    headerTooltip: labels.rec_review.dashboard.tooltip.adr_lyf,
    /*  valueFormatter: (params: ValueFormatterParams) => {
      return twoDecimalRateFormatter(params?.value);
    }, */
    cellRenderer: (params: ICellRendererParams) => {
      return (
        <RateCellRender
          params={params}
          digitFormatter={twoDecimalRateFormatter}
        />
      );
    },
    source: ColumnSources.KPI,
  },
  {
    headerName: 'RevPAR',
    field: 'revpar_live',
    headerTooltip: labels.rec_review.dashboard.tooltip.revpar,
    /*  valueFormatter: (params: ValueFormatterParams) => {
      return twoDecimalRateFormatter(params?.value);
    }, */
    cellRenderer: (params: ICellRendererParams) => {
      return renderLiveData(
        { ...params },
        'revpar_live',
        'revpar',
        true,
        twoDecimalRateFormatter
      );
    },
    comparator: liveDataComparator('revpar'),
    source: ColumnSources.KPI,
  },
  {
    headerName: 'RevPAR\n(LYST)',
    field: 'revpar_lyst',
    headerTooltip: labels.rec_review.dashboard.tooltip.revpar_lyst,
    /* valueFormatter: (params: ValueFormatterParams) => {
      return twoDecimalRateFormatter(params?.value);
    }, */
    cellRenderer: (params: ICellRendererParams) => {
      return (
        <RateCellRender
          params={params}
          digitFormatter={twoDecimalRateFormatter}
        />
      );
    },
    source: ColumnSources.KPI,
  },
  {
    headerName: 'RevPAR\n(LYF)',
    field: 'revpar_lyf',
    headerTooltip: labels.rec_review.dashboard.tooltip.revpar_lyf,
    /* valueFormatter: (params: ValueFormatterParams) => {
      return twoDecimalRateFormatter(params?.value);
    }, */
    cellRenderer: (params: ICellRendererParams) => {
      return (
        <RateCellRender
          params={params}
          digitFormatter={twoDecimalRateFormatter}
        />
      );
    },
    source: ColumnSources.KPI,
  },
  {
    headerName: 'Occ %',
    field: 'occ_pct_live',
    headerTooltip: labels.rec_review.dashboard.tooltip.occ_pct,
    valueFormatter: (params: ValueFormatterParams) => {
      return oneDecimalFormatter(params?.value);
    },
    cellRenderer: (params: ICellRendererParams) => {
      return renderLiveData(
        { ...params },
        'occ_pct_live',
        'occ_pct',
        false,
        oneDecimalFormatter
      );
    },
    comparator: liveDataComparator('occ_pct'),
    source: ColumnSources.KPI,
  },
  {
    headerName: 'Occ %\n(LYST)',
    field: 'occ_pct_lyst',
    headerTooltip: labels.rec_review.dashboard.tooltip.occ_pct_lyst,
    valueFormatter: (params: ValueFormatterParams) => {
      return oneDecimalFormatter(params?.value);
    },
    source: ColumnSources.KPI,
  },
  {
    headerName: 'Occ %\n(LYF)',
    field: 'occ_pct_lyf',
    headerTooltip: labels.rec_review.dashboard.tooltip.occ_pct_lyf,
    valueFormatter: (params: ValueFormatterParams) => {
      return oneDecimalFormatter(params?.value);
    },
    source: ColumnSources.KPI,
  },
  {
    headerName: 'Curr\nRev',
    field: 'current_revenue_live',
    headerTooltip: labels.rec_review.dashboard.tooltip.current_revenue,
    valueFormatter: (params: ValueFormatterParams) => {
      return thousandSeperatorRateFormatter(params?.value);
    },
    cellRenderer: (params: ICellRendererParams) => {
      return renderLiveData(
        { ...params },
        'current_revenue_live',
        'current_revenue',
        true,
        thousandSeperatorRateFormatter,
        true
      );
    },
    comparator: liveDataComparator('current_revenue'),
    source: ColumnSources.KPI,
  },
  {
    headerName: 'Fcst\nRev',
    field: 'fcst_rev',
    headerTooltip: labels.rec_review.dashboard.tooltip.fcst_rev,
    valueFormatter: (params: ValueFormatterParams) => {
      return thousandSeperatorRateFormatter(params?.value);
    },
    cellRenderer: (params: ICellRendererParams) => {
      return (
        <LiveDataField
          liveVal={null}
          oldVal={params.value?.toString()}
          enableCurrencyFormatter={true}
          removeDecimals={true}
        />
      );
    },
    source: ColumnSources.KPI,
  },
];
export const pickupColsData: SourceColumn[] = [
  {
    headerName: '1D P/U',
    field: 'pkp_day_1',
    headerTooltip: labels.rec_review.dashboard.tooltip.pkp_day_1,
    valueFormatter: (params: ValueFormatterParams) => {
      return zeroDecimalFormatter(params?.value);
    },
    source: ColumnSources.PICKUP,
  },
  {
    headerName: '3D P/U',
    field: 'pkp_day_3',
    headerTooltip: labels.rec_review.dashboard.tooltip.pkp_day_3,
    valueFormatter: (params: ValueFormatterParams) => {
      return zeroDecimalFormatter(params?.value);
    },
    source: ColumnSources.PICKUP,
  },
  {
    headerName: '7D P/U',
    field: 'pkp_day_7',
    headerTooltip: labels.rec_review.dashboard.tooltip.pkp_day_7,
    valueFormatter: (params: ValueFormatterParams) => {
      return zeroDecimalFormatter(params?.value);
    },
    source: ColumnSources.PICKUP,
  },
  {
    headerName: '1D P/U\nLY',
    field: 'pkp_day_1_ly',
    headerTooltip: labels.rec_review.dashboard.tooltip.pkp_day_1_ly,
    valueFormatter: (params: ValueFormatterParams) => {
      return zeroDecimalFormatter(params?.value);
    },
    source: ColumnSources.PICKUP,
  },
  {
    headerName: '3D P/U\nLY',
    field: 'pkp_day_3_ly',
    headerTooltip: labels.rec_review.dashboard.tooltip.pkp_day_3_ly,
    valueFormatter: (params: ValueFormatterParams) => {
      return zeroDecimalFormatter(params?.value);
    },
    source: ColumnSources.PICKUP,
  },
  {
    headerName: '7D P/U\nLY',
    field: 'pkp_day_7_ly',
    headerTooltip: labels.rec_review.dashboard.tooltip.pkp_day_7_ly,
    valueFormatter: (params: ValueFormatterParams) => {
      return zeroDecimalFormatter(params?.value);
    },
    source: ColumnSources.PICKUP,
  },
  {
    headerName: 'P/U DOA\nLY',
    field: 'pkp_doa_ly',
    headerTooltip: labels.rec_review.dashboard.tooltip.pkp_doa_ly,
    valueFormatter: (params: ValueFormatterParams) => {
      return zeroDecimalFormatter(params?.value);
    },
    source: ColumnSources.PICKUP,
  },
  {
    headerName: '1D P/U (Tr)',
    field: 'transient_pkp_day_1',
    headerTooltip: labels.rec_review.dashboard.tooltip.transient_pickup_day_1,
    valueFormatter: (params: ValueFormatterParams) => {
      return zeroDecimalFormatter(params?.value);
    },
    source: ColumnSources.PICKUP,
  },
  {
    headerName: '3D P/U (Tr)',
    field: 'transient_pkp_day_3',
    headerTooltip: labels.rec_review.dashboard.tooltip.transient_pickup_day_3,
    valueFormatter: (params: ValueFormatterParams) => {
      return zeroDecimalFormatter(params?.value);
    },
    source: ColumnSources.PICKUP,
  },
  {
    headerName: '7D P/U (Tr)',
    field: 'transient_pkp_day_7',
    headerTooltip: labels.rec_review.dashboard.tooltip.transient_pickup_day_7,
    valueFormatter: (params: ValueFormatterParams) => {
      return zeroDecimalFormatter(params?.value);
    },
    source: ColumnSources.PICKUP,
  },
  {
    headerName: '1D P/U (Gr)',
    field: 'group_pkp_day_1',
    headerTooltip: labels.rec_review.dashboard.tooltip.group_pickup_day_1,
    valueFormatter: (params: ValueFormatterParams) => {
      return zeroDecimalFormatter(params?.value);
    },
    source: ColumnSources.PICKUP,
  },
  {
    headerName: '3D P/U (Gr)',
    field: 'group_pkp_day_3',
    headerTooltip: labels.rec_review.dashboard.tooltip.group_pickup_day_3,
    valueFormatter: (params: ValueFormatterParams) => {
      return zeroDecimalFormatter(params?.value);
    },
    source: ColumnSources.PICKUP,
  },
  {
    headerName: '7D P/U (Gr)',
    field: 'group_pkp_day_7',
    headerTooltip: labels.rec_review.dashboard.tooltip.group_pickup_day_7,
    valueFormatter: (params: ValueFormatterParams) => {
      return zeroDecimalFormatter(params?.value);
    },
    source: ColumnSources.PICKUP,
  },
];
export const ratesColsData: SourceColumn[] = [
  {
    headerName: 'Prev\nBWH Rate',
    field: 'last_current_rate',
    headerTooltip: labels.rec_review.dashboard.tooltip.last_current_rate,
    /* valueFormatter: (params: ValueFormatterParams) => {
      return twoDecimalRateFormatter(params?.value);
    }, */
    cellRenderer: (params: ICellRendererParams) => {
      return (
        <RateCellRender
          params={params}
          digitFormatter={twoDecimalRateFormatter}
        />
      );
    },
    source: ColumnSources.RATES,
  },
  {
    headerName: 'Prev\nRec Rate',
    field: 'prev_rec_rate',
    headerTooltip: labels.rec_review.dashboard.tooltip.prev_rec_rate,
    /*  valueFormatter: (params: ValueFormatterParams) => {
      return twoDecimalRateFormatter(params?.value);
    }, */
    cellRenderer: (params: ICellRendererParams) => {
      return (
        <RateCellRender
          params={params}
          digitFormatter={twoDecimalRateFormatter}
        />
      );
    },
    source: ColumnSources.RATES,
  },
  {
    headerName: 'Rate\nVariance',
    field: 'rate_variance',
    headerTooltip: labels.rec_review.dashboard.tooltip.rate_variance,
    /* valueFormatter: (params: ValueFormatterParams) => {
      return twoDecimalRateFormatter(params?.value);
    }, */
    cellRenderer: (params: ICellRendererParams) => {
      return (
        <RateCellRender
          params={params}
          digitFormatter={twoDecimalRateFormatter}
        />
      );
    },
    source: ColumnSources.RATES,
  },
  {
    headerName: 'Yoy Rate\n(LYST)',
    field: 'yoy_rate_lyst',
    headerTooltip: labels.rec_review.dashboard.tooltip.yoy_rate_lyst,
    /* valueFormatter: (params: ValueFormatterParams) => {
      return twoDecimalRateFormatter(params?.value);
    }, */
    cellRenderer: (params: ICellRendererParams) => {
      return (
        <RateCellRender
          params={params}
          digitFormatter={twoDecimalRateFormatter}
        />
      );
    },
    source: ColumnSources.RATES,
  },
  {
    headerName: 'Yoy Rate\n(LYF)',
    field: 'yoy_rate_lyf',
    headerTooltip: labels.rec_review.dashboard.tooltip.yoy_rate_lyf,
    /*  valueFormatter: (params: ValueFormatterParams) => {
      return twoDecimalRateFormatter(params?.value);
    }, */
    cellRenderer: (params: ICellRendererParams) => {
      return (
        <RateCellRender
          params={params}
          digitFormatter={twoDecimalRateFormatter}
        />
      );
    },
    source: ColumnSources.RATES,
  },
  /* 
  TODO Currently, We do not have enough information for final rec rate, so we are going to hide this in the code for now (B20V-2125).
  {
    headerName: 'Final\nRec Rate',
    field: 'final_rec_rate',
    headerTooltip: labels.rec_review.dashboard.tooltip.final_rec_rate,
    valueFormatter: (params: ValueFormatterParams) => {
      return twoDecimalRateFormatter(params?.value);
    },
    source: ColumnSources.RATES,
  }, */
  {
    headerName: 'Derived\nRate',
    field: 'derived_rate',
    headerTooltip: labels.rec_review.dashboard.tooltip.derived_rate,
    /* valueFormatter: (params: ValueFormatterParams) => {
      return twoDecimalRateFormatter(params?.value);
    }, */
    cellRenderer: (params: ICellRendererParams) => {
      return (
        <RateCellRender
          params={params}
          digitFormatter={twoDecimalRateFormatter}
        />
      );
    },
    source: ColumnSources.RATES,
  },
];
