import './chat-panel.scss';

import { MdMinimize, MdOutlineClose } from 'react-icons/md';
import { useEffect, useRef, useState } from 'react';

import { IoArrowUpCircleSharp } from 'react-icons/io5';
import React from 'react';
import { Tooltip } from '@mui/material';
import axios from 'axios';
import { labels } from 'locales/en.label';
import { uniqueId } from 'lodash';
import { TrackingEnum } from 'types/TrackingTypes';
import { useAppTracking } from 'hooks/useTracking';
import { useOktaAuth } from '@okta/okta-react';

interface ChatPanelProps {
  toggleChat: React.Dispatch<React.SetStateAction<boolean>>;
}

interface Message {
  role: 'user' | 'assistant';
  content: Array<{
    text: string;
  }>;
}

const TypingIndicator = () => {
  return (
    <div className='typing-indicator'>
      <div className='typing-dot'></div>
      <div className='typing-dot'></div>
      <div className='typing-dot'></div>
    </div>
  );
};

function ChatPanel({ toggleChat }: ChatPanelProps) {
  const [messages, setMessages] = useState<Message[]>([]);
  const [newMessage, setNewMessage] = useState('');
  const [chatMinimized, setChatMinimized] = useState(false);
  const [waiting, setWaiting] = useState(false);
  const { track } = useAppTracking();

  const { authState } = useOktaAuth();
  const token = authState?.accessToken?.accessToken;

  const messagesEndRef = useRef<HTMLDivElement>(null);
  const chatMessagesRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messages]);

  useEffect(() => {
    if (!chatMinimized && chatMessagesRef.current) {
      chatMessagesRef.current.scrollTop = chatMessagesRef.current.scrollHeight;
    }
  }, [chatMinimized]);

  const handleMinimize = (event: React.SyntheticEvent) => {
    event.stopPropagation();
    setChatMinimized(true);
  };

  const handleHeaderClick = () => {
    if (chatMinimized) {
      setChatMinimized(false);
    }
  };

  const handleUpdateMessage = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setNewMessage(event.target.value);
  };

  const handleSendMessage = async () => {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    };
    const userMessage = newMessage.trim();
    setMessages([
      ...messages,
      { role: 'user', content: [{ text: userMessage }] },
    ]);
    setNewMessage('');
    setWaiting(true);
    try {
      const request = await axios.post(
        process.env.REACT_APP_BEDROCK_API_URL! + '/bedrock',
        {
          message: userMessage,
          conversation: messages,
        },
        {
          headers,
        }
      );
      const response = request.data.conversation;
      const usage = request.data.usage;

      track(
        {
          action: 'ChatPanel-Message',
          component: 'chat-panel',
          value: JSON.stringify({
            chatMessageId: uniqueId(),
            messageSent: userMessage,
            conversationOld: messages,
            conversationNew: response,
            usage,
          }),
        },
        TrackingEnum.CHAT_AI_DATA_LAKE
      );

      setMessages(response);
    } catch (error) {
      setMessages([
        ...messages,
        {
          role: 'user',
          content: [{ text: userMessage }],
        },
        {
          role: 'assistant',
          content: [{ text: labels.chat.error }],
        },
      ]);
    }
    setWaiting(false);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === 'Enter') {
      if (newMessage.trim() === '') {
        return event.preventDefault();
      }

      if (event.shiftKey) {
        return;
      }
      event.preventDefault();
      handleSendMessage();
    }
  };

  const formatText = (text: string) => {
    return text.split('\n').map((line, index) => (
      <React.Fragment key={index}>
        {line}
        <br />
      </React.Fragment>
    ));
  };

  return (
    <div className={`chat-window ${!chatMinimized && 'chat-full'}`}>
      <header
        className={`${chatMinimized ? 'header-button' : ''}`}
        onClick={handleHeaderClick}
      >
        {labels.chat.title}
        <div className='window-controls'>
          {!chatMinimized && (
            <Tooltip
              title={labels.chat.minimize}
              placement='top'
              arrow
              enterDelay={1500}
            >
              <button onClick={handleMinimize}>
                <MdMinimize className='control-icon' />
              </button>
            </Tooltip>
          )}
          <Tooltip
            title={labels.chat.close}
            placement='top'
            arrow
            enterDelay={1500}
          >
            <button onClick={() => toggleChat(false)}>
              <MdOutlineClose className='control-icon close' />
            </button>
          </Tooltip>
        </div>
      </header>
      {!chatMinimized && (
        <>
          <div className='chat-messages' ref={chatMessagesRef}>
            {messages.map((message, index) => (
              <div className={`message-wrapper wrapper-${message.role}`}>
                <div key={index} className={`message ${message.role}-msg`}>
                  {formatText(message.content[0].text)}
                </div>
              </div>
            ))}
            {waiting && <TypingIndicator />}
            <div ref={messagesEndRef} />
          </div>
          <div className='chat-input'>
            <textarea
              onChange={(e) => handleUpdateMessage(e)}
              onKeyDown={handleKeyDown}
              autoFocus
              placeholder={labels.chat.placeholder}
              value={newMessage}
            ></textarea>
            <Tooltip
              title={labels.chat.send}
              placement='top'
              arrow
              enterDelay={1500}
            >
              <button onClick={handleSendMessage}>
                <IoArrowUpCircleSharp className='send-button' />
              </button>
            </Tooltip>
          </div>
        </>
      )}
    </div>
  );
}

export default ChatPanel;
