import {
  useAcceptAllPropertyRecsMutation,
  useResetPropertyRecsMutation,
  useUpdateRecMutation,
} from '../gql/_gen_/property-rec-rates.gql';

export const useAcceptAllRecRates = () => {
  return useAcceptAllPropertyRecsMutation();
};

export const useResetRecRates = (refetchRecRates = false) => {
  return useResetPropertyRecsMutation({
    ...(refetchRecRates && { refetchQueries: ['GetPropertyRecRates'] }),
  });
};

export const useUpdateRecRate = () => {
  return useUpdateRecMutation();
};
