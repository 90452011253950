import { FaChartLine, FaRegCalendarCheck, FaUsersCog } from 'react-icons/fa';
import { MdGroups2, MdHelpCenter, MdSegment, MdSettings } from 'react-icons/md';

import { VscHome } from 'react-icons/vsc';
import { labels } from '../../locales/en.label';

const appStage = process.env.REACT_APP_STAGE || 'prod';

export const navMenuItems =
  appStage === 'prod' || appStage === 'stage' || appStage === 'staging'
    ? [
        {
          to: '/home',
          icon: VscHome,
          title: labels.nav_bar.home,
          color: '#FFFFFF',
        },
        {
          to: '/my-view/',
          icon: MdSegment,
          title: labels.nav_bar.my_view,
          color: '#FFFFFF',
        },
        {
          to: '/events',
          icon: FaRegCalendarCheck,
          title: labels.nav_bar.events,
          color: '#FFFFFF',
        },

        /*  TODO B20V-2284 Hiding non-active menus until they are ready 
  {
    to: '/insights',
    icon: FaRegLightbulb,
    title: labels.nav_bar.insights,
    color: '#FFFFFF',
  }, */
        {
          to: '/rules',
          icon: FaChartLine,
          title: labels.nav_bar.rules,
          color: '#FFFFFF',
        },
        {
          to: '/groups',
          icon: MdGroups2,
          title: labels.nav_bar.groups,
          color: '#FFFFFF',
        },
        {
          to: '/settings',
          icon: MdSettings,
          title: labels.nav_bar.settings,
          color: '#FFFFFF',
        },
        {
          to: '/admin',
          icon: FaUsersCog,
          title: labels.nav_bar.admin,
          color: '#FFFFFF',
        },
        {
          to: '/help',
          icon: MdHelpCenter,
          title: labels.nav_bar.help,
          color: '#FFFFFF',
        },
      ]
    : [
        {
          to: '/home',
          icon: VscHome,
          title: labels.nav_bar.home,
          color: '#FFFFFF',
        },
        {
          to: '/my-view/',
          icon: MdSegment,
          title: labels.nav_bar.my_view,
          color: '#FFFFFF',
        },
        {
          to: '/events',
          icon: FaRegCalendarCheck,
          title: labels.nav_bar.events,
          color: '#FFFFFF',
        },
        /*  TODO B20V-2284 Hiding non-active menus until they are ready 
  {
    to: '/insights',
    icon: FaRegLightbulb,
    title: labels.nav_bar.insights,
    color: '#FFFFFF',
  },
];
}, */
        {
          to: '/rules',
          icon: FaChartLine,
          title: labels.nav_bar.rules,
          color: '#FFFFFF',
        },
        {
          to: '/groups',
          icon: MdGroups2,
          title: labels.nav_bar.groups,
          color: '#FFFFFF',
        },
        {
          to: '/settings',
          icon: MdSettings,
          title: labels.nav_bar.settings,
          color: '#FFFFFF',
        },
        {
          to: '/admin',
          icon: FaUsersCog,
          title: labels.nav_bar.admin,
          color: '#FFFFFF',
        },
        {
          to: '/help',
          icon: MdHelpCenter,
          title: labels.nav_bar.help,
          color: '#FFFFFF',
        },
      ];
