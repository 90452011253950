import './number-input.scss';

import {
  InputAdornment,
  OutlinedInput,
  OutlinedInputProps,
} from '@mui/material';
import { useCallback, useState } from 'react';

import { numberValidationSchema } from './number-input-validation';
import { usePropertyContext } from 'context/propertyContext';

interface NumberInputProps extends OutlinedInputProps {
  isFloat?: boolean;
  showCurrencySymbol?: boolean;
  maxAmount?: number;
  minAmount?: number;
  styles?: React.CSSProperties;
  allowSigns?: boolean;
}

export const NumberInput = ({
  value,
  onChange,
  isFloat = false,
  showCurrencySymbol = true,
  maxAmount,
  minAmount,
  allowSigns = false,
  styles,
  ...others
}: NumberInputProps) => {
  const { currencySymbol } = usePropertyContext();
  const [error, setError] = useState<string | null>(null);
  const startAdornment = showCurrencySymbol ? (
    <InputAdornment position='start'>{currencySymbol}</InputAdornment>
  ) : undefined;

  const validNumber = numberValidationSchema(
    allowSigns,
    isFloat,
    minAmount!,
    maxAmount!
  );
  const validateAndUpdate = useCallback(
    (
      value: string,
      updateFn: (event: React.ChangeEvent<HTMLInputElement>) => void
    ) => {
      try {
        const validatedValue = validNumber.parse(value);
        const event = {
          target: { value: validatedValue?.toString() ?? '' },
        } as React.ChangeEvent<HTMLInputElement>;
        updateFn(event);
      } catch (err) {
        console.error(err);
      }
    },
    [validNumber]
  );

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    validateAndUpdate(event.target.value, () => {
      onChange?.(event);
    });
  };

  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    let numberVal = parseFloat(event.target.value);
    if (Number.isNaN(numberVal)) numberVal = 0;
    event.target.value = numberVal.toFixed(isFloat ? 2 : 0);
    onChange?.(event);
  };

  return (
    <div className='common-dropdown-container' style={styles}>
      <OutlinedInput
        className='number-input'
        autoComplete='off'
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        startAdornment={startAdornment}
        {...others}
      />
    </div>
  );
};
